<template>
  <!-- App Searchbar Header -->
  <div class="app-fixed-search d-flex align-items-center">

    <!-- Toggler -->
    <div class="sidebar-toggle d-block d-lg-none ml-1">
      <feather-icon
        icon="MenuIcon"
        size="21"
        class="cursor-pointer"
        @click="$emit('mqShallShowLeftSidebar')"
      />
    </div>

    <!-- Searchbar -->
    <div class="ml-1 d-flex align-content-center justify-content-between w-100">
      <v-select
        v-model="employeeFilter"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        placeholder="Selecione o dentista"
        :options="filteredEmployeeOptions"
        class="w-100"
      >
        <template #no-options="{}">
          Para este período, nenhum dentista com agenda encontrado.
        </template>
      </v-select>
      <b-form-checkbox
        v-model="withScheduleInDate"
        class="ml-1 mt-1"
        style="white-space: nowrap;"
        @change="withScheduleInDateChange"
      >
        Com agenda
      </b-form-checkbox>
    </div>
  </div>

</template>

<script>
import {
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  onMounted, ref, computed, watch,
} from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BFormCheckbox,

    vSelect,
  },
  props: {
    employeeOptions: {
      type: Array,
      required: true,
    },
  },

  setup(props, { emit }) {
    const employeeFilter = ref(null)
    const filteredEmployeeOptions = ref(JSON.parse(JSON.stringify(props.employeeOptions)))
    const withScheduleInDate = ref(true)
    watch(employeeFilter, val => {
      emit('employeeFilter', val)
    })
    const selectedDays = computed(() => store.state['store-schedule'].selectedDays)

    const filterEmployeeOptions = (dentistId = null, dentistsOfDays = []) => {
      // reset select off filter
      employeeFilter.value = null
      filteredEmployeeOptions.value = []
      const localEmployeeOptions = JSON.parse(JSON.stringify(props.employeeOptions))
      if (withScheduleInDate.value) {
        filteredEmployeeOptions.value = localEmployeeOptions.filter(employee => selectedDays.value.some(
          some => (
            (employee.data.office_hour && employee.data.office_hour.days.some(day => day.activated === true && day.value === some.weekdayPosition))
            || dentistsOfDays.some(dod => dod.dentist_id === employee.data.id)
          ),
        ))
      } else filteredEmployeeOptions.value = localEmployeeOptions

      if (dentistId) {
        employeeFilter.value = filteredEmployeeOptions.value.find(e => e.value === dentistId)
      }
    }

    onMounted(() => {
      filterEmployeeOptions()
    })

    const withScheduleInDateChange = () => {
      employeeFilter.value = null
      filterEmployeeOptions()
    }

    return {
      employeeFilter,
      withScheduleInDate,
      filteredEmployeeOptions,
      withScheduleInDateChange,
      filterEmployeeOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
