<template>

  <div>
    <add-patient-modal
      :show="handleAddPatientShowModal"
      @hidden="handleAddPatientShowModal = false"
      @showToast="showToast"
      @refetchData="refetchData"
    />

    <b-modal
      id="modal-search-patient"
      v-model="showLocal"
      centered
      size="xl"
      title="Buscar Paciente"
      hide-footer
      @hidden="hidden"
    >

      <b-alert
        v-if="!schedules.length"
        variant="warning"
        show
      >
        <h4 class="alert-heading">
          Atenção!
        </h4>
        <div class="alert-body">
          <p>Por Favor selecione ao menos um horário.</p>
        </div>
      </b-alert>

      <!-- Table Container Card -->
      <b-card
        v-else
        no-body
        class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Exibir</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>resultados</label>
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="8"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Buscar..."
                  debounce="500"
                />
                <b-button
                  variant="primary"
                  @click="handleAddPatientShowModal = true"
                >
                  <span class="text-nowrap">Adicionar Paciente</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refUserListTable"
          class="position-relative tableMinHeght"
          :items="fetchUsers"
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Nenhum resultado encontrado"
          :sort-desc.sync="isSortDirDesc"
          no-sort-reset
        >

          <!-- Column: Patient -->
          <template #cell(name)="data">
            <b-media
              class="cursor-pointer"
              vertical-align="center"
              @click="addSchedule(data.item)"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.photo ? data.item.photo.url : null"
                  :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                />
              </template>
              <b-link
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.name }}
              </b-link>
              <b-badge
                pill
                :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                class="text-capitalize"
              >
                {{ data.item.status === 1 ? 'Ativo' : 'Inativo' }}
              </b-badge>
            </b-media>
          </template>

          <!-- Column: Role -->
          <template #cell(responsibles)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{ data.item.responsibles.length ? data.item.responsibles[0].name : '' }}</span>
            </div>
          </template>

          <!-- Column: Phones -->
          <template #cell(phones)="data">
            {{ resolveUserPhones(data.item.phones) }}
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item @click="addSchedule(data.item)">
                <feather-icon icon="PlusIcon" />
                <span
                  class="align-middle ml-50"
                >Agendar</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'apps-patients-edit', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Prontuário</span>
              </b-dropdown-item>

              <b-dropdown-item @click="handleDeletePatient(data.item)">
                <feather-icon icon="TrashIcon" />
                <span
                  class="align-middle ml-50"
                >Excluir</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  toRefs, ref, watch, onUnmounted,
} from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import userStoreModule from '@/views/apps/patients/patientStoreModule'
import usePatientsList from './usePatientsList'
import addPatientModal from './AddPatientModal.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BAlert,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,

    addPatientModal,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    schedules: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const showLocal = ref(false)

    const { show } = toRefs(props)
    watch(show, newValue => {
      showLocal.value = newValue
    })

    const USER_APP_STORE_MODULE_NAME = 'store-patient'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const handleAddPatientShowModal = ref(false)

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserPhones,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = usePatientsList(emit, props)

    const hidden = () => {
      // resetData()
      emit('hidden')
    }

    const addSchedule = patient => {
      hidden()
      emit('saveSchedules', patient)
    }

    return {
      showLocal,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      statusOptions,
      handleAddPatientShowModal,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserPhones,

      fetchUsers,
      hidden,
      refetchData,
      addSchedule,
    }
  },
  methods: {
    handleDeletePatient(patient) {
      this.$bvModal
        .msgBoxConfirm(`Deseja Excluir o Paciente ${patient.name}?`, {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) this.deletePatient(patient)
        })
    },

    async deletePatient(patient) {
      try {
        await store.dispatch('store-patient/delete', patient)
        this.showToast('success', 'CheckIcon', 'Sucesso', 'Dados Excluídos com Sucesso.')
        this.refetchData()
      } catch (error) {
        this.showOverlay = false
        if (error.response.status === 412) {
          this.showToast('warning', 'AlertCircleIcon', 'Atenção', error.response.data.message)
        } else {
          this.showToast('danger', 'AlertCircleIcon', 'Atenção', error.message)
        }
      }
    },
    showToast(variant = 'success', icon = 'CheckIcon', title = 'Sucesso', text = 'Dados Salvos com Sucesso.') {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
