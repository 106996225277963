<template>
  <validation-observer
    #default="{ invalid }"
    ref="refFormObserver"
  >
    <b-modal
      id="modal-handle-arrival"
      v-model="showLocal"
      centered
      size="sm"
      :title="title"
      hide-footer
      @hidden="hidden"
    >
      <b-alert
        v-if="!dentist.id"
        variant="warning"
        show
      >
        <h4 class="alert-heading">
          Atenção!
        </h4>
        <div class="alert-body">
          <p>Por Favor selecione um dentista.</p>
        </div>
      </b-alert>

      <div v-else>
        <b-form>
          <b-row>
            <!-- Field: Data  -->
            <b-col
              cols="12"
              md="12"
              lg="12"
            >
              <validation-provider
                #default="validationContext"
                name="Data"
                rules="required"
                immediate
              >
                <b-form-group
                  label="Data"
                  label-for="delete-form-period-start"
                >
                  <b-form-input
                    id="delete-form-period-start"
                    v-model="dataForm.date"
                    :state="getValidationState(validationContext) ? null : false"
                    type="date"
                    trim
                  />
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row
            v-for="(time, idx) in dataForm.times"
            :key="`${idx}-time`"
          >
            <b-col
              cols="12"
              md="12"
            >
              <validation-provider
                #default="validationContext"
                name="Horário"
                :vid="`${idx}-time`"
                rules="required"
                immediate
              >

                <b-input-group>
                  <b-form-input
                    :id="`${idx}-time`"
                    :key="`${idx}-time`"
                    v-model="time.start"
                    type="time"
                    trim
                    :state="getValidationState(validationContext) ? null : false"
                  />
                  <b-input-group-append>
                    <b-button
                      v-if="idx == 0"
                      variant="outline-primary"
                      @click="addTime"
                    >
                      <feather-icon
                        class="cursor-pointer text-success"
                        icon="PlusCircleIcon"
                        size="15"
                      />
                    </b-button>
                    <b-button
                      v-else
                      variant="outline-primary"
                      @click="removeTime(idx)"
                    >
                      <feather-icon
                        class="cursor-pointer text-danger"
                        icon="MinusCircleIcon"
                        size="15"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Footer -->
          <b-row
            class="mt-2"
          >
            <b-col
              cols="6"
            >
              <b-button
                variant="outline-secondary"
                block
                @click="hidden"
              >
                Cancelar
              </b-button>
            </b-col>

            <b-col
              cols="6"
            >
              <b-button
                variant="primary"
                block
                :disabled="invalid"
                @click="add"
              >
                Adicionar
              </b-button>
            </b-col>
          </b-row>

        </b-form>

      </div>

    </b-modal>

  </validation-observer>
</template>

<script>
import {
  toRefs, ref, watch, computed,
} from '@vue/composition-api'
import {
  BModal, BButton, BAlert, BRow, BCol, BFormInvalidFeedback,
  BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
// import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BAlert,
    BForm,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dentist: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const showLocal = ref(false)
    const dentistLocal = ref({})

    const { show, dentist } = toRefs(props)

    const blankDataForm = {
      date: null,
      times: [
        {
          start: null,
          avaiable: true,
        },
      ],
    }

    const dataForm = ref(JSON.parse(JSON.stringify(blankDataForm)))

    watch(dentist, newValue => {
      dentistLocal.value = JSON.parse(JSON.stringify(newValue))
    })

    watch(show, newValue => {
      showLocal.value = newValue
    })

    const title = computed(() => `${dentistLocal.value.name || 'Atenção'} ${dentistLocal.value.specialities ? `${` | ${dentistLocal.value.specialities.map(e => e.speciality).join(', ')}`}` : ''}`)

    const hidden = () => {
      emit('hidden')
      dentistLocal.value = JSON.parse(JSON.stringify(dentist.value))
      dataForm.value = JSON.parse(JSON.stringify(blankDataForm))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(hidden)

    const schedules = ref([])

    const add = () => {
      emit('add', dataForm, dentist)
    }

    const addTime = () => {
      dataForm.value.times.push({
        start: null,
        avaiable: true,
      })
    }

    const removeTime = idx => {
      dataForm.value.times.splice(idx, 1)
    }

    return {
      showLocal,
      dentistLocal,
      title,
      schedules,
      refFormObserver,
      dataForm,
      getValidationState,
      resetForm,

      hidden,
      addTime,
      removeTime,
      add,
    }
  },
}
</script>
