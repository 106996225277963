<template>
  <div class="calendar-date-selector">
    <span @click="selectPrevious">
      <!-- eslint-disable-next-line vue/no-parsing-error -->
      {{ '<' }}
    </span>
    <span @click="selectCurrent">Hoje</span>
    <span @click="selectNext">></span>
  </div>
</template>

<script>
import 'dayjs/locale/pt-br'
import dayjs from 'dayjs'

export default {
  name: 'CalendarModeSelector',

  props: {
    currentDate: {
      type: String,
      required: true,
    },

    selectedDate: {
      type: Object,
      required: true,
    },
  },

  methods: {
    selectPrevious() {
      const newSelectedDate = dayjs(this.selectedDate).subtract(1, 'month').locale('pt-br')
      this.$emit('dateSelected', newSelectedDate)
    },

    selectCurrent() {
      const newSelectedDate = dayjs(this.currentDate).locale('pt-br')
      this.$emit('dateSelected', newSelectedDate)
    },

    selectNext() {
      const newSelectedDate = dayjs(this.selectedDate).add(1, 'month').locale('pt-br')
      this.$emit('dateSelected', newSelectedDate)
    },
  },
}
</script>

<style lang="scss">
.calendar-date-selector {
  display: flex;
  justify-content: space-between;
  width: 80px;
  color: #3e4e63;
}

.calendar-date-selector > * {
  cursor: pointer;
  user-select: none;
}
</style>
