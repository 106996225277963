<template>
  <b-modal
    id="modal-handle-arrival"
    v-model="showLocal"
    centered
    size="xl"
    :title="title"
    @hidden="hidden"
  >

    <b-alert
      v-if="!dentist.id"
      variant="warning"
      show
    >
      <h4 class="alert-heading">
        Atenção!
      </h4>
      <div class="alert-body">
        <p>Por Favor selecione um dentista.</p>
      </div>
    </b-alert>

    <div v-else>
      <b-row>
        <b-col
          cols="12"
          md="8"
        >
          <CalendarMonth
            ref="calendarMonth"
            :dentist="dentist"
            :schedules="schedules"
            @dateClicked="dateClicked"
            @getSchedules="getSchedules"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-card>
            <h3>{{ selectedDay | dateTime }}</h3>
            <hr>
            <div class="price-details">
              <h5 class="price-title">
                Horários Vagos
              </h5>
              <ul class="list-unstyled">
                <li
                  v-for="time in dentistDayFreeTimesWork"
                  :key="time"
                  class="price-detail"
                >
                  <div class="detail-title">
                    <span
                      class="cursor-pointer"
                      @click="selectTimeSchedule(time)"
                    >
                      {{ time }}
                    </span>
                  </div>
                </li>
              </ul>
              <hr>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <template #modal-footer="{ cancel }">

      <span class="mr-auto">
        <b-badge
          variant="dark"
          class="float-right ml-1"
        >
          Domingo
        </b-badge>

        <b-badge
          variant="light"
          class="float-right ml-1"
        >
          <span style="color: black;">
            Não atende
          </span>
        </b-badge>

        <b-badge
          variant="primary"
          class="float-right ml-1"
        >
          Atende
        </b-badge>

        <b-badge
          variant="danger"
          class="float-right ml-1"
        >
          Feriado
        </b-badge>

        <b-badge
          variant="warning"
          class="float-right"
        >
          Agenda cheia
        </b-badge>
      </span>

      <b-button
        variant="outline-secondary"
        @click="cancel()"
      >
        Cancelar
      </b-button>
    </template>

  </b-modal>
</template>

<script>
import {
  toRefs, ref, watch, computed, nextTick,
} from '@vue/composition-api'
import {
  BModal, BButton, BAlert, BRow, BCol, BCard, BBadge,
} from 'bootstrap-vue'
import CalendarMonth from '@/views/components/simple-calendar/CalendarMonth.vue'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BAlert,
    BCard,
    BBadge,
    CalendarMonth,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dentist: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const toast = useToast()

    const showLocal = ref(false)
    const dentistLocal = ref({})
    const calendarMonth = ref(null)
    const showOverlay = ref(false)
    const selectedDay = ref(null)
    const dentistDayTimesWork = ref([])
    const dentistDayFreeTimesWork = ref([])

    const { show, dentist } = toRefs(props)

    watch(dentist, newValue => {
      dentistLocal.value = JSON.parse(JSON.stringify(newValue))
    })

    const title = computed(() => `${dentistLocal.value.name || 'Atenção'} ${dentistLocal.value.specialities ? `${` | ${dentistLocal.value.specialities.map(e => e.speciality).join(', ')}`}` : ''}`)

    const hidden = () => {
      emit('hidden')
      dentistLocal.value = JSON.parse(JSON.stringify(dentist.value))
    }

    const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
      toast({
        component: ToastificationContent,
        props: {
          title: titleToast,
          icon,
          text,
          variant,
        },
      })
    }

    const catchErr = e => {
      showOverlay.value = false
      if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
      else if (e.response && e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
      else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
    }

    const schedules = ref([])

    const getSchedules = async () => {
      try {
        const response = await store
          .dispatch('store-schedule/fetchScheduleList', {
            sortBy: 'id',
            sort: 'asc',
            types: ['CLINICAL'],
            dates: calendarMonth.value.days.map(day => day.date),
            getByDate: true,
            employee_id: dentistLocal.value.id,
            // chair_id: chair.value ? chair.value.id : null,
            // patient_id: patientId.value,
          })
        const { data } = response.data
        schedules.value = data
      } catch (e) {
        catchErr(e)
      }
    }

    watch(show, newValue => {
      showLocal.value = newValue

      if (newValue && dentist.value.id) {
        nextTick(() => {
          getSchedules()
          const dateObj = new Date()
          const [DD, MM, YYYY] = dateObj.toLocaleDateString('pt-BR').split('/')
          selectedDay.value = `${YYYY}-${MM}-${DD}`
        })
      }
    })

    const dateClicked = date => {
      dentistDayFreeTimesWork.value = date.isScheduleDate.freeSchedules
      selectedDay.value = date.date
    }

    const selectTimeSchedule = time => {
      emit('selectTimeSchedule', selectedDay, time, dentist)
    }

    return {
      showLocal,
      dentistLocal,
      title,
      calendarMonth,
      schedules,
      selectedDay,
      dentistDayFreeTimesWork,
      dentistDayTimesWork,

      hidden,
      dateClicked,
      getSchedules,
      selectTimeSchedule,
    }
  },
}
</script>

<style lang="scss">
ol,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.calendar-month-header {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}
</style>
