<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <search-patient-modal
      :show="handleSearchPatientShowModal"
      :schedules="selected"
      @hidden="handleSearchPatientShowModal = false"
      @saveSchedules="saveSchedules"
    />

    <handle-observation
      :show="handleObservationShowModal"
      :observation-data="observationData"
      @save="saveSchedule"
      @hidden="handleObservationShowModal = false"
    />

    <handle-arrival
      :show="handleArrivalShowModal"
      :data="arrivalData"
      @save="saveSchedule"
      @hidden="handleArrivalShowModal = false"
    />

    <handle-dentist-schedule-info
      :show="handleDentistScheduleInfoShowModal"
      :dentist="dentistScheduleInfoData"
      @selectTimeSchedule="selectTimeSchedule"
      @hidden="handleDentistScheduleInfoShowModal = false"
    />

    <handle-add-calendar
      :show="handleAddCalendarShowModal"
      :dentist="dentistScheduleInfoData"
      @hidden="handleAddCalendarShowModal = false"
      @add="addCalendar"
    />

    <!-- Email List -->
    <div class="email-app-list">

      <!-- Filters -->
      <schedule-filter
        v-if="employeeOptions.length"
        ref="refScheduleFilter"
        :patient-options="patientOptions"
        :employee-options="employeeOptions"
        :chair-options="chairOptions"
        @searchPatient="debouncedSearchPatient"
        @employeeFilter="employeeFilter"
        @mqShallShowLeftSidebar="mqShallShowLeftSidebar = true"
      />
      <b-overlay
        v-else
        show
        rounded="sm"
      />

      <!-- App Action Bar -->
      <div
        v-if="itemsData.length"
        class="app-action"
      >
        <div class="action-left">
          <b-form-checkbox
            v-model="allCheckBoxSelected"
            :indeterminate="indeterminateCheckBox"
            @change="toggleAllCheckBox"
          >
            Selecione tudo
          </b-form-checkbox>
        </div>
        <div
          v-show="true"
          class="align-items-center"
          :class="{'d-flex': selectedEmails.length}"
        >
          <!-- Update Mail Folder Dropdown -->
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            right
          >
            <template #button-content>
              <feather-icon
                icon="FolderIcon"
                size="17"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="moveSelectedEmailsToFolder('draft')">
              <feather-icon icon="Edit2Icon" />
              <span class="align-middle ml-50">Draft</span>
            </b-dropdown-item>

            <b-dropdown-item @click="moveSelectedEmailsToFolder('spam')">
              <feather-icon icon="InfoIcon" />
              <span class="align-middle ml-50">Spam</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-show="$route.params.folder !== 'trash'"
              @click="moveSelectedEmailsToFolder('trash')"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Trash</span>
            </b-dropdown-item>
          </b-dropdown>

          <!-- Update Mail Folder Dropdown -->
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            class="ml-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="TagIcon"
                size="17"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="updateSelectedEmailsLabel('personal')">
              <span class="mr-50 bullet bullet-success bullet-sm" />
              <span>Personal</span>
            </b-dropdown-item>
            <b-dropdown-item @click="updateSelectedEmailsLabel('company')">
              <span class="mr-50 bullet bullet-primary bullet-sm" />
              <span>Company</span>
            </b-dropdown-item>
            <b-dropdown-item @click="updateSelectedEmailsLabel('important')">
              <span class="mr-50 bullet bullet-warning bullet-sm" />
              <span>Important</span>
            </b-dropdown-item>
            <b-dropdown-item @click="updateSelectedEmailsLabel('private')">
              <span class="mr-50 bullet bullet-danger bullet-sm" />
              <span>Private</span>
            </b-dropdown-item>
          </b-dropdown>

          <feather-icon
            icon="PrinterIcon"
            size="17"
            class="cursor-pointer ml-1"
            @click="markSelectedEmailsAsUnread"
          />

          <feather-icon
            v-show="$route.params.folder !== 'trash'"
            icon="TrashIcon"
            size="17"
            class="cursor-pointer ml-1"
            @click="handleDelete"
          />

        </div>
      </div>

      <!-- context -->
      <vue-context
        ref="menuProp"
        :close-on-click="closeOnClick"
        @close="closeContextMenu"
      >
        <li v-if="contextUnBlockCalendar">
          <b-link
            class="d-flex align-items-center"
            @click="contextMenuClicked('UNBLOCKCALENDAR')"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            <span class="ml-75">Desbloquear Horário</span>
          </b-link>
        </li>
        <li v-if="contextAdd">
          <b-link
            class="d-flex align-items-center"
            @click="contextMenuClicked('ADD')"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            <span class="ml-75">Criar agendamento</span>
          </b-link>
        </li>
        <li v-if="contextDeleteCalendar">
          <b-link
            class="d-flex align-items-center"
            @click="contextMenuClicked('DELETE_CALENDAR')"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            <span class="ml-75">Excluir horário</span>
          </b-link>
        </li>
        <!-- <li v-if="contextAbsence">
          <b-link
            class="d-flex align-items-center"
            @click="contextMenuClicked('ABSENCE')"
          >
            <feather-icon
              icon="ShuffleIcon"
              size="16"
            />
            <span class="ml-75">Falta sem Justificativa.</span>
          </b-link>
        </li> -->
        <li v-if="contextMedicalRecords">
          <b-link
            class="d-flex align-items-center"
            @click="contextMenuClicked('MEDICAL_RECORDS')"
          >
            <feather-icon
              icon="InfoIcon"
              size="16"
            />
            <span class="ml-75">Prontuário</span>
          </b-link>
        </li>
        <li v-if="contextBudget">
          <b-link
            class="d-flex align-items-center"
            @click="contextMenuClicked('BUDGET')"
          >
            <feather-icon
              icon="DollarSignIcon"
              size="16"
            />
            <span class="ml-75">Orçamento</span>
          </b-link>
        </li>
        <li v-if="contextCashier">
          <b-link
            class="d-flex align-items-center"
            @click="contextMenuClicked('CASHIER')"
          >
            <feather-icon
              icon="DollarSignIcon"
              size="16"
            />
            <span class="ml-75">Caixa</span>
          </b-link>
        </li>
        <li v-if="contextDelete">
          <b-link
            class="d-flex align-items-center"
            @click="contextMenuClicked('DELETE')"
          >
            <feather-icon
              icon="TrashIcon"
              size="16"
            />
            <span class="ml-75">Excluir agendamento</span>
          </b-link>
        </li>
        <!-- <li v-if="contextConsult">
          <b-link
            class="d-flex align-items-center"
            @click="contextMenuClicked('CONSULT')"
          >
            <feather-icon
              icon="SearchIcon"
              size="16"
            />
            <span class="ml-75">Ir ao prontuário</span>
          </b-link>
        </li>
        <li v-if="contextBlockEmployee">
          <b-form-textarea
            v-model="blockEmployeeMotivation"
            placeholder="Motivo do Bloqueio..."
            rows="5"
          />

          <vs-textarea
            v-model="blockEmployeeMotivation"
            placeholder="Motivo do Bloqueio"
            class="mb-4"
            counter="120"
            maxlength="120"
            rows="5"
          />
          <b-link
            class="d-flex align-items-center"
            @click="contextMenuClicked('BLOCK_EMPLOYEE')"
          >
            <feather-icon
              icon="MinusIcon"
              size="16"
            />
            <span class="ml-75">pode colocar várias opções aqui</span>
          </b-link>
        </li> -->
      </vue-context>

      <!-- Schedule -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="email-user-list scroll-area"
      >

        <b-overlay
          :show="showOverlay"
          rounded="sm"
        >
          <b-form-checkbox-group
            v-model="selected"
          >
            <b-table-simple
              v-for="(item, idxData) in items"
              :key="idxData"
              hover
              small
              bordered
              striped
            >
              <b-thead>
                <b-tr>
                  <b-th :colspan="tableColumns.length">
                    {{ item.id | dateTime }}
                  </b-th>
                </b-tr>
                <b-tr>
                  <b-th
                    v-for="(col, idxColHeader) in tableColumns"
                    :key="idxColHeader"
                    class="cursor-pointer"
                  >
                    {{ col.label }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tr
                v-for="(rowData, idxRowData) in item.data"
                :key="idxRowData"
                :class="(!rowData.avaiable || rowData.status === 'CANCELED') ? 'strikeout' : 'avaiable'"
              >
                <b-td
                  v-for="(col, idxCol) in tableColumns"
                  :key="idxCol"
                  class="cursor-pointer"
                  @dblclick="editCol(rowData, col)"
                  @contextmenu.prevent="contextMenu($event, rowData, col)"
                >
                  <b-form-checkbox
                    v-if="col.key === 'select' && rowData.avaiable && rowData.status !== 'CANCELED'"
                    :value="rowData"
                    @click.native.stop
                  />
                  <b-form-input
                    v-else-if="col.key === 'code' && rowData.edit === col.key"
                    placeholder=""
                    autofocus
                    autocomplete="off"
                    @keyup.esc="rowData.edit = false"
                    @blur.stop="rowData.edit = false"
                    @keyup.enter="saveSchedule(col.key, $event.target.value, rowData)"
                  />
                  <v-select
                    v-else-if="col.key === 'dental_plan' && rowData.edit === col.key"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dentalPlanOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                    :value="rowData.dental_plans"
                    @option:selected="(value) => saveSchedule(col.key, value, rowData)"
                    @close="rowData.edit = false"
                    @search:blur="rowData.edit = false"
                  />
                  <v-select
                    v-else-if="col.key === 'status' && rowData.edit === col.key"
                    v-model="rowData.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.value"
                    :options="[
                      { label: 'Não Confirmado', value: 'NOT_CONFIRMED' },
                      { label: 'Confirmado', value: 'CONFIRMED' },
                      { label: 'Cancelado', value: 'CANCELED' },
                      { label: 'Faltou', value: 'ABSENSE' },
                      { label: 'Atendido', value: 'ATTENDED' },
                      { label: 'Em Atendimento', value: 'INPROGRESS' },
                      { label: 'Bloqueado', value: 'BLOCKED' },
                      { label: 'Emergência', value: 'EMERGENCY' },
                      { label: 'Livre', value: 'EMPTY' },
                    ]"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                    :value="rowData.status"
                    @option:selected="value => saveSchedule(col.key, value.value, rowData)"
                    @input="value => dupliqueCalendarIfCanceled(value, rowData)"
                    @close="rowData.edit = false"
                    @search:blur="rowData.edit = false"
                  />
                  <span v-else-if="col.key === 'arrival_time'">
                    {{ rowData[col.key] | dateTime('timeNoSecond', 'h') }}
                  </span>
                  <span v-else-if="col.key === 'auxiliar'">
                    {{ rowData[col.key] && rowData[col.key].name }}
                  </span>
                  <span v-else-if="col.key !== 'observation' && col.key !== 'arrival_time'">
                    {{ col.key === 'status' ? handleStatus(rowData[col.key]) : rowData[col.key] }}
                  </span>
                  <span
                    v-else-if="rowData.id"
                    class="cursor-pointer d-flex justify-content-center align-items-center"
                  >
                    <feather-icon
                      :class="rowData.observation ? 'text-primary' : 'text-muted'"
                      icon="InfoIcon"
                      size="22"
                      @click="handleObservation(rowData)"
                    />
                  </span>
                </b-td>
              </b-tr>

              <b-tr
                v-if="!itemsData.length"
              >
                <b-td :colspan="tableColumns.length">
                  <div class="text-center my-2">
                    Nenhum resultado encontrado
                  </div>
                </b-td>
              </b-tr>

            </b-table-simple>
          </b-form-checkbox-group>
        </b-overlay>

      </vue-perfect-scrollbar>
    </div>

    <!-- Schedule View/Detail -->
    <schedule-view
      :class="{'show': showScheduleDetails}"
      @close-schedule-view="showScheduleDetails = false"
      @move-email-to-folder="moveOpenEmailToFolder"
      @toggle-email-starred="toggleStarred(emailViewData)"
      @update-email-label="updateOpenEmailLabel"
      @mark-email-unread="markOpenEmailAsUnread"
      @change-opened-email="changeOpenedEmail"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <left-sidebar
        ref="leftSidebar"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
        @change-date="changeDate"
        @handleSearchPatient="handleSearchPatient"
        @handleDelete="handleDelete"
        @handleDentistScheduleInfo="handleDentistScheduleInfo"
        @handleAddCalendar="handleAddCalendar"
        @handleDeleteCalendar="handleDeleteCalendar"
      />
    </portal>

  </div>
</template>

<script>
import {
  ref, computed,
} from '@vue/composition-api'
import {
  BDropdown, BDropdownItem, BFormCheckbox, BFormCheckboxGroup, BLink,
  BOverlay, BTableSimple, BThead, BTr, BTh, BTd, BFormInput,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import store from '@/store'
import _ from 'lodash'
import VueContext from 'vue-context'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import LeftSidebar from './LeftSidebar.vue'
import ScheduleView from './View.vue'
import ScheduleFilter from './Filter.vue'
import useSchedule from './useSchedule'
import HandleObservation from './modals/HandleObservation.vue'
import HandleArrival from './modals/HandleArrival.vue'
import HandleDentistScheduleInfo from './modals/HandleDentistScheduleInfo.vue'
import HandleAddCalendar from './modals/HandleAddCalendar.vue'
import searchPatientModal from './modals/SearchPatientModal.vue'
import usePatientsList from './modals/usePatientsList'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BFormCheckboxGroup,
    BLink,
    BOverlay,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BFormInput,
    searchPatientModal,

    // 3rd Party
    VuePerfectScrollbar,
    VueContext,

    // App SFC
    LeftSidebar,
    ScheduleView,
    ScheduleFilter,
    vSelect,
    HandleObservation,
    HandleArrival,
    HandleDentistScheduleInfo,
    HandleAddCalendar,
  },

  setup(props, { root }) {
    const {
      totalData,
      refScheduleListTable,
      refScheduleFilter,
      leftSidebar,
      items,
      selected,
      tableColumns,
      employeeOptions,
      allCheckBoxSelected,
      indeterminateCheckBox,
      itemsData,
      searchQuery,
      showOverlay,
      dentalPlanOptions,
      handleObservationShowModal,
      observationData,
      handleArrivalShowModal,
      arrivalData,
      handleDentistScheduleInfoShowModal,
      handleAddCalendarShowModal,
      dentistScheduleInfoData,

      refetchData,
      toggleAllCheckBox,
      editCol,
      fetchEmployeeOptions,
      changeDate,
      employeeFilter,
      showToast,
      catchErr,
      saveSchedule,
      saveSchedules,
      dupliqueCalendarIfCanceled,
      selectTimeSchedule,
      cancelSchedules,
      handleStatus,
      handleObservation,
      handleDentistScheduleInfo,
      handleAddCalendar,
      handleDeleteCalendar,
      unblockCalendar,
      addCalendar,
    } = useSchedule(root)

    const {
      handleSearchPatientShowModal,
      handleSearchPatient,
    } = usePatientsList(selected)

    // const weekDays = ref([0, 'SEG.', 'TER.', 'QUA.', 'QUI.', 'SEX.', 'SÁB.', 'DOM.'])

    const patientOptions = ref([])
    const chairOptions = ref([])

    // const patientId = ref(null)
    // const chair = ref(null)
    // const type = ref('CLINICAL') // CLINICAL, ODONTOLOGICAL, COMMITMENT - For ADD/Edit (string)
    // const observation = ref('')
    // const scheduleStatus = ref('NOT_CONFIRMED') // NOT_CONFIRMED - CONFIRMED - CANCELED - ABSENSE - ATTENDED - INPROGRESS, BLOCKED
    const closeOnClick = ref(true)
    const contextAdd = ref(false)
    const contextDeleteCalendar = ref(false)
    // const contextAbsence = ref(false)
    const contextMedicalRecords = ref(false)
    const contextBudget = ref(false)
    const contextCashier = ref(false)
    const contextDelete = ref(false)
    const contextConsult = ref(false)
    // const contextBlockEmployee = ref(false)
    const contextUnBlockCalendar = ref(false)
    const blockEmployeeMotivation = ref('')
    const selectedDay = ref(null)
    const selectedHour = ref(null)
    const menuProp = ref(null)
    // const idxHour = ref(-1)
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const showScheduleDetails = ref(false)

    fetchEmployeeOptions()

    const fetchPatientOptions = async (val, loading) => {
      if (val.length) {
        loading(true)
        const resp = await store.dispatch('app-user/fetch', {
          q: val,
          roles: ['PATIENT'],
        })

        patientOptions.value = resp.data.map(o => ({
          value: o.id,
          label: `${o.name} ${o.cpf || ''}`,
          data: o,
        }))

        loading(false)
      } else {
        loading(false)
      }
    }
    const debouncedSearchPatient = _.debounce(fetchPatientOptions, 600)

    const goToMedicalRecords = () => {
      router.push({ name: 'apps-patients-edit', params: { id: selected.value[0].patientId } })
    }

    const goToCashier = () => {
      alert('A IDEIA AQUI É IR PARA O CONTAS A RECEBER JÁ FILTRANDO PELO NOME DO PACIENTE CORRETO?')
    }

    const goToBudget = () => {
      alert('COMO POSSO SABER PARA QUAL ORÇAMENTO IR UMA VEZ QUE NÃO TENHO NO AGENDAMENTO ESSA INFORMAÇÃO? PENSO QUE IR AO PRONTUÁRIO É O SUFICIENTE')
    }

    const handleDelete = () => {
      if (!selected.value.some(e => e.patientId)) {
        root.$swal({
          title: 'Aviso!',
          text: 'Selecione ao menos um agendamento.',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }

      const schedules = JSON.parse(JSON.stringify(selected.value))
      root.$bvModal
        .msgBoxConfirm('Deseja excluir o(s) agendamento(s)?', {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(resp => {
          if (resp) cancelSchedules(schedules)
        })
    }

    const contextMenu = (event, rowData) => {
      closeOnClick.value = true
      contextAdd.value = false
      contextDeleteCalendar.value = false
      contextMedicalRecords.value = false
      contextBudget.value = false
      contextCashier.value = false
      contextDelete.value = false
      contextConsult.value = false
      // contextBlockEmployee.value = false
      contextUnBlockCalendar.value = false
      // contextAbsence.value = false

      if (!rowData.avaiable) {
        contextUnBlockCalendar.value = true
        selected.value = [rowData]
        menuProp.value.open(event)
        return
      }

      if (!rowData.id) {
        contextAdd.value = true
        contextDeleteCalendar.value = true
        selected.value = [rowData]
      }

      if (rowData.id) {
        contextMedicalRecords.value = true
        contextBudget.value = true
        contextCashier.value = true
        contextDelete.value = true
        selected.value = [rowData]
      }

      // if (schedule) {
      // A REGRA PARA MOTOS É ALUNO DIFERENTE, MOTO DIFERENTE (SOMENTE 2 MOTOS NO MEMO HORÁRIO) E MESMO INSTRUTOR
      // VERIFICA SE TODOS OS DADOS FORAM PREENCHIDOS E SE AS REGRAS SE CUMPREM PARA DUPLO AGENDAMENTO
      // if ((patientId.value && employeeId.value && chair.value) && (schedule.patient_id !== patientId.value && chair.value.id !== schedule.cheir_id)) {
      // const sch = fillSchedule(selectedDay, hour)
      // if (sch.length < 2) {
      //   // this.selectedDay = selectedDay
      //   // this.selectedHour = hour
      // }
      // }
      // MOSTRA A OPÇÃO DE ALTERAR O STATUS PARA AUSÊNCIA
      // if (schedule.status !== 'ABSENCE' && schedule.status !== 'BLOCKED') contextAbsence.value = true

      // store.commit('store-schedule/SET_SCHEDULE', schedule)
      // menuProp.value.open(event)
      // } else if (!fillSchedule(selectedDay, hour).length) {
      //   // CONTEXTO EM DATA SEM AGENDAMENTO - BLOQUEIO DE INSTRUTOR
      // closeOnClick.value = false
      // contextBlockEmployee.value = true
      blockEmployeeMotivation.value = ''

      menuProp.value.open(event)
    }

    const contextMenuClicked = action => {
      if (action === 'DELETE_CALENDAR') {
        handleDeleteCalendar()
      }

      if (action === 'UNBLOCKCALENDAR') {
        unblockCalendar()
      }

      if (action === 'ADD') {
        handleSearchPatient()
      }

      if (action === 'MEDICAL_RECORDS') {
        goToMedicalRecords()
      }

      if (action === 'CASHIER') {
        goToCashier()
      }

      if (action === 'BUDGET') {
        goToBudget()
      }

      if (action === 'DELETE') {
        handleDelete()
      }

      if (action === 'CONSULT') {
        router.push({ name: 'apps-patients-edit', params: { id: selected.value[0].patientId } })
      }

      if (action === 'ABSENCE') { // AUSÊNCIA SEM JUSTIFICATIVA
        // updateStatus('ABSENCE')
      } else if (action === 'BLOCK_EMPLOYEE') {
        // eslint-disable-next-line no-alert
        alert('uhull')
        // blockEmployee()
      }
    }

    const closeContextMenu = () => {
      if (!handleSearchPatientShowModal.value) selected.value = []
    }

    const detailScheduleShow = schedule => {
      store.commit('store-schedule/SET_SCHEDULE', schedule)

      // ao abrir o detalhe preciso ver todas as agendas do paciente

      showScheduleDetails.value = true
    }

    // const verifyWorkDays = (employee, selDay, hour) => {
    //   const weekdaysWork = ['work_dom', 'work_seg', 'work_ter', 'work_qua', 'work_qui', 'work_sex', 'work_sab']
    //   const weekdaysStartTimeWork = ['start_time_dom', 'start_time_seg', 'start_time_ter', 'start_time_qua', 'start_time_qui', 'start_time_sex', 'start_time_sab']
    //   const weekdaysFinalTimeWork = ['final_time_dom', 'final_time_seg', 'final_time_ter', 'final_time_qua', 'final_time_qui', 'final_time_sex', 'final_time_sab']
    //   const [startTime, endTime] = hour.split(' ')

    //   if (!employee.office_hour[weekdaysWork[selDay.weekdayPosition]]) {
    //     // NÃO TRABALHA NESTE DIA
    //     showToast('warning', 'AlertCircleIcon', 'Atenção', `O funcionário ${employee.name} NÃO trabalha neste dia.`)

    //     if (employee.office_hour.block_time) {
    //       return 'STOP'
    //     }
    //   } else {
    //     const startTimeWork = moment(employee.office_hour[weekdaysStartTimeWork[selDay.weekdayPosition]], 'h:mm:ss')
    //     const finalTimeWork = moment(employee.office_hour[weekdaysFinalTimeWork[selDay.weekdayPosition]], 'h:mm:ss')
    //     // VERIFICAR SE TRABALHA NO HORÁRIO DEFINIDO
    //     const startTimeBetweenWork = moment(`${startTime}:00`, 'h:mm:ss').isBetween(startTimeWork, finalTimeWork, undefined, '[)')
    //     const endTimeBetweenWork = moment(`${endTime}:00`, 'h:mm:ss').isBetween(startTimeWork, finalTimeWork, undefined, '[)')
    //     if (!startTimeBetweenWork || !endTimeBetweenWork) {
    //       showToast('warning', 'AlertCircleIcon', 'Atenção', `O funcionário ${employee.name} NÃO trabalha neste horário.`)

    //       if (employee.office_hour.block_time) {
    //         return 'STOP'
    //       }
    //     } else if (employee.office_hour.lunch_time) {
    //       // VERIFICAR SE NÃO ESTÁ EM ALMOÇO
    //       const startTimeLunch = moment(employee.office_hour.lunch_time_ini, 'h:mm:ss')
    //       const finalTimeLunch = moment(employee.office_hour.lunch_time_fin, 'h:mm:ss')
    //       const startTimeBetweenLunch = moment(`${startTime}:00`, 'h:mm:ss').isBetween(startTimeLunch, finalTimeLunch, undefined, '[)')
    //       const endTimeBetweenLunch = moment(`${endTime}:00`, 'h:mm:ss').isBetween(startTimeLunch, finalTimeLunch, undefined, '[)')
    //       if (startTimeBetweenLunch || endTimeBetweenLunch) {
    //         // EM HORÁRIO DE ALMOÇO
    //         showToast('warning', 'AlertCircleIcon', 'Atenção', `O funcionário ${employee.name} NÃO trabalha neste horário.`)

    //         if (employee.office_hour.block_time) {
    //           return 'STOP'
    //         }
    //       }
    //     }
    //     if (employee.office_hour.breaks && employee.office_hour.breaks.length) {
    //       // VERIFICAR SE NÃO ESTÁ EM FOLGA
    //       const idx = employee.office_hour.breaks.findIndex(a => {
    //         const startTimeBreak = moment(`${a.startTime}:00`, 'h:mm:ss')
    //         const finalTimeBreak = moment(`${a.endTime}:00`, 'h:mm:ss')
    //         const startTimeBetweenBreak = moment(`${startTime}:00`, 'h:mm:ss').isBetween(startTimeBreak, finalTimeBreak, undefined, '[)')
    //         const endTimeBetweenBreak = moment(`${endTime}:00`, 'h:mm:ss').isBetween(startTimeBreak, finalTimeBreak, undefined, '[)')
    //         return a.weekday === selDay.weekdayPosition && (startTimeBetweenBreak || endTimeBetweenBreak)
    //       })
    //       if (idx >= 0) {
    //         // ESTÁ EM FOLGA
    //         showToast('warning', 'AlertCircleIcon', 'Atenção', `O funcionário ${employee.name} está em período de folga neste horário.`)

    //         if (employee.office_hour.block_time) {
    //           return 'STOP'
    //         }
    //       }
    //     }
    //   }
    //   return 'GO' // STOP PARA PARAR NÃO PERMITINDO O AGENDAMENTO
    // }

    // const verifyWarnings = selDay => selDay // STOP PARA PARAR NÃO PERMITINDO O AGENDAMENTO

    // *******************************************************************************************************************************

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // Search Query
    // const searchQuery = ref('')
    // watch(routeQuery, val => {
    //   searchQuery.value = val
    // })
    // // eslint-disable-next-line no-use-before-define
    // watch(searchQuery, () => fetchEmails())
    const updateRouteQuery = val => {
      const currentRouteQuery = ''

      if (val) currentRouteQuery.q = val
      else delete currentRouteQuery.q
    }

    // ------------------------------------------------
    // Mail Selection
    // ------------------------------------------------
    const selectedEmails = ref([])

    const selectAllEmailCheckbox = computed(() => true)
    const isSelectAllEmailCheckboxIndeterminate = computed(() => true)

    const markSelectedEmailsAsUnread = () => {
      // uuu
    }

    // ------------------------------------------------
    // Email Details
    // ------------------------------------------------
    const emailViewData = ref({})

    const moveOpenEmailToFolder = () => {
    }
    const updateOpenEmailLabel = () => {
    }

    const markOpenEmailAsUnread = () => {
    }

    const changeOpenedEmail = () => {
    }

    return {
      // UI
      perfectScrollbarSettings,

      // Mail Selection
      selectAllEmailCheckbox,
      isSelectAllEmailCheckboxIndeterminate,
      selectedEmails,
      // toggleSelectedMail,

      markSelectedEmailsAsUnread,

      // Email Details
      showScheduleDetails,
      emailViewData,
      moveOpenEmailToFolder,
      updateOpenEmailLabel,
      markOpenEmailAsUnread,
      changeOpenedEmail,

      // Search Query
      searchQuery,
      updateRouteQuery,

      // Left Sidebar Responsiveness
      mqShallShowLeftSidebar,

      // weekDays,
      closeOnClick,
      contextAdd,
      contextDeleteCalendar,
      // contextAbsence,
      contextMedicalRecords,
      contextBudget,
      contextCashier,
      contextDelete,
      contextConsult,
      // contextBlockEmployee,
      contextUnBlockCalendar,
      blockEmployeeMotivation,
      selectedDay,
      selectedHour,
      menuProp,
      patientOptions,
      employeeOptions,
      chairOptions,

      allCheckBoxSelected,
      indeterminateCheckBox,
      showOverlay,

      debouncedSearchPatient,
      saveSchedule,
      saveSchedules,
      dupliqueCalendarIfCanceled,
      selectTimeSchedule,
      contextMenu,
      contextMenuClicked,
      detailScheduleShow,

      totalData,
      refetchData,
      toggleAllCheckBox,
      editCol,
      refScheduleListTable,
      refScheduleFilter,
      leftSidebar,
      items,
      selected,
      tableColumns,
      itemsData,
      dentalPlanOptions,
      handleObservationShowModal,
      handleSearchPatientShowModal,
      observationData,
      handleArrivalShowModal,
      arrivalData,
      handleDentistScheduleInfoShowModal,
      handleAddCalendarShowModal,
      dentistScheduleInfoData,

      employeeFilter,
      changeDate,
      showToast,
      catchErr,
      handleStatus,
      handleObservation,
      handleDentistScheduleInfo,
      handleAddCalendar,
      handleDeleteCalendar,
      addCalendar,
      handleSearchPatient,
      unblockCalendar,
      closeContextMenu,
      handleDelete,
    }
  },
}
</script>

<style lang="scss">
  @import "~@core/scss/base/pages/app-email.scss";
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-context.scss';
  .dark-layout {
    div ::v-deep {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
    }
  }

</style>
