<template>
  <b-card
    class="mb-1"
    no-body
  >
    <b-card-header
      v-if="schedule.patient"
      class="email-detail-head"
    >
      <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
        <b-avatar
          size="48"
          :src="schedule.patient.photo ? schedule.patient.photo.url : null"
          class="mr-75"
        />
        <div class="mail-items">
          <h5 class="mb-0">
            {{ schedule.patient.name }}
          </h5>
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            class="email-info-dropup"
          >
            <template #button-content>
              <span class="font-small-3 text-muted mr-25">{{ schedule.patient.email }}</span>
              <feather-icon
                icon="ChevronDownIcon"
                size="10"
              />
            </template>
            <table class="table table-sm table-borderless font-small-3">
              <tbody>
                <tr>
                  <td class="text-right text-muted align-top">
                    From:
                  </td>
                  <td>{{ schedule.patient.email }}</td>
                </tr>
                <tr>
                  <td class="text-right text-muted align-top">
                    To:
                  </td>
                  <td>{{ schedule.patient.email }}</td>
                </tr>
                <tr>
                  <td class="text-right text-muted align-top">
                    Date:
                  </td>
                  <td>4:25 AM 13 Jan 2018</td>
                </tr>
              </tbody>
            </table>
          </b-dropdown>
        </div>
      </div>
      <div class="mail-meta-item d-flex align-items-center">
        <small class="mail-date-time text-muted">{{ dateTime(schedule.start, 'dateTimeNoSecond') }}</small>
        <!-- Mail Action DD -->
        <b-dropdown
          variant="link"
          no-caret
          toggle-class="p-0"
          right
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="17"
              class="ml-50 text-body"
            />
          </template>

          <b-dropdown-item>
            <feather-icon icon="CornerUpLeftIcon" />
            <span class="align-middle ml-50">Reply</span>
          </b-dropdown-item>

          <b-dropdown-item>
            <feather-icon icon="CornerUpRightIcon" />
            <span class="align-middle ml-50">Forward</span>
          </b-dropdown-item>

          <b-dropdown-item>
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </b-card-header>
    <!--  -->
  </b-card>
</template>

<script>
import {
  BDropdown, BDropdownItem, BCard, BCardHeader, BAvatar,
} from 'bootstrap-vue'
import { dateTime } from '@core/utils/filter'

export default {
  components: {
    BDropdown, BDropdownItem, BCard, BCardHeader, BAvatar,
  },
  props: {
    schedule: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      dateTime,
    }
  },
}
</script>

<style>

</style>
