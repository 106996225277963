<template>
  <b-modal
    id="modal-handle-observation"
    v-model="showLocal"
    centered
    size="md"
    title="Observações"
    @hidden="hidden"
  >

    <div>
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label-for="observation"
          >
            <b-form-textarea
              id="observation"
              v-model="observationDataLocal.observation"
              trim
              rows="5"
              no-resize
              placeholder=""
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <template #modal-footer="{ cancel }">

      <b-button
        variant="outline-secondary"
        @click="cancel()"
      >
        Cancelar
      </b-button>
      <b-button
        variant="primary"
        @click="save"
      >
        Salvar
      </b-button>

    </template>

  </b-modal>
</template>

<script>
import { toRefs, ref, watch } from '@vue/composition-api'
import {
  BModal, BFormGroup, BButton, BCol, BRow, BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormTextarea,
    BButton,
    BCol,
    BRow,

  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    observationData: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const showLocal = ref(false)
    const observationDataLocal = ref({})

    // necessário para observar props
    const { show, observationData } = toRefs(props)
    watch(show, newValue => {
      showLocal.value = newValue
    })

    watch(observationData, newValue => {
      observationDataLocal.value = JSON.parse(JSON.stringify(newValue))
    })

    const save = () => {
      emit('save', null, null, observationDataLocal.value)
      emit('hidden')
    }

    const hidden = () => {
      emit('hidden')
      observationDataLocal.value = JSON.parse(JSON.stringify(observationData.value))
    }

    return {
      showLocal,
      observationDataLocal,
      hidden,
      save,
    }
  },
}
</script>

<style>

</style>
