var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"calendar-day",class:{
    'calendar-day--not-current': !_vm.day.isCurrentMonth,
    'calendar-day--today': _vm.isToday,
    'calendar-day--holiday': _vm.day.isHoliday,
    'calendar-day--sunday': _vm.day.weekDay === 0,
    'calendar-day--does-not-attend': _vm.day.isScheduleDate.dentistDayTimesWork.length === 0 && _vm.day.weekDay !== 0 && !_vm.day.isHoliday,
    'calendar-day--attend-free-time': _vm.day.isScheduleDate.dentistDayTimesWork.length && _vm.day.isScheduleDate.freeSchedules.length && _vm.day.weekDay !== 0 && !_vm.day.isHoliday,
    'calendar-day--does-attend-not-free-time': _vm.day.isScheduleDate.dentistDayTimesWork.length && !_vm.day.isScheduleDate.freeSchedules.length && _vm.day.weekDay !== 0 && !_vm.day.isHoliday,
  },attrs:{"id":"calendar-day","title":_vm.title},on:{"click":_vm.dateClicked}},[_c('span',[_vm._v(_vm._s(_vm.label))])])}
var staticRenderFns = []

export { render, staticRenderFns }