<template>
  <li
    id="calendar-day"
    :title="title"
    class="calendar-day"
    :class="{
      'calendar-day--not-current': !day.isCurrentMonth,
      'calendar-day--today': isToday,
      'calendar-day--holiday': day.isHoliday,
      'calendar-day--sunday': day.weekDay === 0,
      'calendar-day--does-not-attend': day.isScheduleDate.dentistDayTimesWork.length === 0 && day.weekDay !== 0 && !day.isHoliday,
      'calendar-day--attend-free-time': day.isScheduleDate.dentistDayTimesWork.length && day.isScheduleDate.freeSchedules.length && day.weekDay !== 0 && !day.isHoliday,
      'calendar-day--does-attend-not-free-time': day.isScheduleDate.dentistDayTimesWork.length && !day.isScheduleDate.freeSchedules.length && day.weekDay !== 0 && !day.isHoliday,
    }"
    @click="dateClicked"
  >
    <!-- {{ day.isScheduleDate.dentistDayTimesWork }} -->
    <!-- {{ day.isScheduleDate.ocupedSchedules }}
    {{ day.isScheduleDate.dentistDayTimesWork }}
    {{ day.isScheduleDate.freeSchedules }} -->

    <span>{{ label }}</span>
  </li>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'CalendarMonthDayItem',

  props: {
    day: {
      type: Object,
      required: true,
    },

    isCurrentMonth: {
      type: Boolean,
      default: false,
    },

    isToday: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    label() {
      return `${this.day.isHoliday ? '*' : ''} ${dayjs(this.day.date).format('D')}`
    },
    title() {
      if (this.day.isScheduleDate.dentistDayTimesWork.length === 0 && this.day.weekDay !== 0 && !this.day.isHoliday) {
        return 'Não atende.'
      }
      if (this.day.isScheduleDate.dentistDayTimesWork.length && this.day.isScheduleDate.freeSchedules.length && this.day.weekDay !== 0 && !this.day.isHoliday) {
        return 'Horários livres.'
      }
      if (this.day.isScheduleDate.dentistDayTimesWork.length && !this.day.isScheduleDate.freeSchedules.length && this.day.weekDay !== 0 && !this.day.isHoliday) {
        return 'Agenda cheia.'
      }
      return this.day.isHoliday && this.day.isHoliday.holidayName
    },
  },

  methods: {
    dateClicked() {
      if (!this.day.isHoliday && this.day.weekDay !== 0) this.$emit('dateClicked', this.day)
    },
  },
}
</script>

<style scoped>
.calendar-day {
  position: relative;
  min-height: 100px;
  font-size: 16px;
  background-color: #fff;
  color: #3e4e63;
  padding: 5px;
}

.calendar-day > span {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 2px;
}

.calendar-day--not-current {
  background-color: #e4e9f0;
  color: #b5c0cd;
}

.calendar-day--today {
  padding-top: 4px;
}

.calendar-day--today > span {
  color: #fff;
  border-radius: 9999px;
  background-color: #3e4e63;
}

.calendar-day--holiday {
  background-color: #ea5455;
}
.calendar-day--holiday > span {
  color: #fff;
}

.calendar-day--sunday {
  background-color: #4b4b4b;
}
.calendar-day--sunday > span {
  color: #fff;
}

.calendar-day--does-not-attend {
  background-color: #f6f6f6;
}
.calendar-day--does-not-attend > span {
  color: #000;
}

.calendar-day--attend-free-time {
  background-color: #9900cc;
}
.calendar-day--attend-free-time > span {
  color: #fff;
}

.calendar-day--does-attend-not-free-time {
  background-color: #ff9f43;
}
.calendar-day--does-attend-not-free-time > span {
  color: #fff;
}
</style>
