import {
  ref, watch, computed,
} from '@vue/composition-api'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default function useUsersList() {
  const handleSearchPatientShowModal = ref(false)
  const handleSearchPatient = () => {
    handleSearchPatientShowModal.value = true
  }

  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'code', label: 'ficha', sortable: true },
    { key: 'name', label: 'paciente', sortable: true },
    { key: 'phones', label: 'telefone', sortable: false },
    { key: 'responsibles', label: 'responsável', sortable: true },
    {
      key: 'currentPlan',
      label: 'plano',
      formatter: title,
      sortable: true,
    },
    { key: 'actions', label: 'ações' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('store-patient/fetchPatients', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sort: isSortDirDesc.value ? 'desc' : 'asc',
      })
      .then(response => {
        const { data, total } = response.data

        callback(data)
        totalUsers.value = total
      })
      .catch(e => {
        let message = 'Erro ao preencher lista de pacientes!'
        if (e.response.status === 401) message = 'Sua sessão expirou, faça novo login!'
        toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 1) return 'success'
    if (status === 0) return 'secondary'
    return 'primary'
  }

  const resolveUserPhones = phones => phones.map(phone => phone.phone).join(' | ')

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    handleSearchPatientShowModal,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserPhones,
    refetchData,
    handleSearchPatient,
  }
}
