<template>
  <div class="calendar-month">
    <div class="calendar-month-header">
      <CalendarDateIndicator
        :selected-date="selectedDate"
        class="calendar-month-header-selected-month"
      />

      <CalendarDateSelector
        :current-date="today"
        :selected-date="selectedDate"
        @dateSelected="selectDate"
      />
    </div>

    <CalendarWeekdays />

    <ol class="days-grid">
      <CalendarMonthDayItem
        v-for="day in days"
        :key="day.date"
        :day="day"
        :is-today="day.date === today"
        @dateClicked="dateClicked"
      />
    </ol>
  </div>
</template>

<script>
import 'dayjs/locale/pt-br'
import isBetween from 'dayjs/plugin/isBetween'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import axios from '@axios'
import CalendarMonthDayItem from './CalendarMonthDayItem.vue'
import CalendarDateIndicator from './CalendarDateIndicator.vue'
import CalendarDateSelector from './CalendarDateSelector.vue'
import CalendarWeekdays from './CalendarWeekdays.vue'

dayjs.extend(weekday)
dayjs.extend(weekOfYear)

export default {
  name: 'CalendarMonth',

  components: {
    CalendarMonthDayItem,
    CalendarDateIndicator,
    CalendarDateSelector,
    CalendarWeekdays,
  },

  props: {
    schedules: {
      type: Array,
      default: () => [],
    },
    dentist: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      selectedDate: dayjs().locale('pt-br'),
      startDateConsult: null,
      endDateConsult: null,
      holidays: [],
      dentistCalendarWork: [],
    }
  },

  computed: {
    days() {
      this.setHolidays()
      return [
        ...this.previousMonthDays,
        ...this.currentMonthDays,
        ...this.nextMonthDays,
      ]
    },

    today() {
      return dayjs().format('YYYY-MM-DD')
    },

    month() {
      return Number(this.selectedDate.format('M'))
    },

    year() {
      return Number(this.selectedDate.format('YYYY'))
    },

    numberOfDaysInMonth() {
      return dayjs(this.selectedDate).daysInMonth()
    },

    currentMonthDays() {
      return [...Array(this.numberOfDaysInMonth)].map((day, index) => ({
        date: dayjs(`${this.year}-${this.month}-${index + 1}`).format('YYYY-MM-DD'),
        weekDay: this.getWeekday(`${this.year}-${this.month}-${index + 1}`),
        isHoliday: this.isHoliday(dayjs(`${this.year}-${this.month}-${index + 1}`).format('YYYY-MM-DD')),
        isScheduleDate: this.isScheduleDate(dayjs(`${this.year}-${this.month}-${index + 1}`)),
        isCurrentMonth: true,
      }))
    },

    previousMonthDays() {
      const firstDayOfTheMonthWeekday = this.getWeekday(
        this.currentMonthDays[0].date,
      )
      const previousMonth = dayjs(`${this.year}-${this.month}-01`).subtract(
        1,
        'month',
      )

      // Cover first day of the month being sunday (firstDayOfTheMonthWeekday === 0)
      const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday
        ? firstDayOfTheMonthWeekday - 1
        : 6

      const previousMonthLastMondayDayOfMonth = dayjs(
        this.currentMonthDays[0].date,
      )
        .subtract(visibleNumberOfDaysFromPreviousMonth, 'day')
        .date()

      return [...Array(visibleNumberOfDaysFromPreviousMonth)].map(
        (day, index) => ({
          date: dayjs(`${previousMonth.year()}-${previousMonth.month() + 1}-${previousMonthLastMondayDayOfMonth + index}`).format('YYYY-MM-DD'),
          weekDay: this.getWeekday(`${previousMonth.year()}-${previousMonth.month() + 1}-${previousMonthLastMondayDayOfMonth + index}`),
          isHoliday: this.isHoliday(dayjs(`${previousMonth.year()}-${previousMonth.month() + 1}-${previousMonthLastMondayDayOfMonth + index}`).format('YYYY-MM-DD')),
          isScheduleDate: this.isScheduleDate(dayjs(`${previousMonth.year()}-${previousMonth.month() + 1}-${previousMonthLastMondayDayOfMonth + index}`)),
          isCurrentMonth: false,
        }),
      )
    },

    nextMonthDays() {
      const lastDayOfTheMonthWeekday = this.getWeekday(
        `${this.year}-${this.month}-${this.currentMonthDays.length}`,
      )

      const nextMonth = dayjs(`${this.year}-${this.month}-01`).add(1, 'month')

      const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
        ? 7 - lastDayOfTheMonthWeekday
        : lastDayOfTheMonthWeekday

      return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => ({
        date: dayjs(`${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`).format('YYYY-MM-DD'),
        weekDay: this.getWeekday(`${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`),
        isHoliday: this.isHoliday(dayjs(`${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`).format('YYYY-MM-DD')),
        isScheduleDate: this.isScheduleDate(dayjs(`${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`)),
        isCurrentMonth: false,
      }))
    },
  },

  async mounted() {
    await this.getCalendar()
  },

  methods: {
    getWeekday(date) {
      return dayjs(date).weekday()
    },

    selectDate(newSelectedDate) {
      if (this.selectedDate.format('YYYY-MM-DD') !== newSelectedDate.format('YYYY-MM-DD')) {
        this.selectedDate = newSelectedDate
        this.setSchedules()
      }
      this.selectedDate = newSelectedDate
    },

    dateClicked(date) {
      this.$emit('dateClicked', date)
    },

    async setHolidays() {
      dayjs.extend(isBetween)

      if (!this.startDateConsult
        || !this.endDateConsult
        || (!this.startDateConsult.isBetween(
          dayjs(`${this.year}-${this.month}-01`).subtract(6, 'month'),
          dayjs(`${this.year}-${this.month}-01`).add(6, 'month'), 'day', '[)',
        )
        && !this.endDateConsult.isBetween(
          dayjs(`${this.year}-${this.month}-01`).subtract(6, 'month'),
          dayjs(`${this.year}-${this.month}-01`).add(6, 'month'), 'day', '[)',
        ))) {
        this.startDateConsult = dayjs(`${this.year}-${this.month}-01`).subtract(6, 'month')
        this.endDateConsult = dayjs(`${this.year}-${this.month}-01`).add(6, 'month')

        const resp = await axios.get(`${process.env.VUE_APP_API_URL}/holidays/${this.startDateConsult.format('YYYY-MM-DD')}/${this.endDateConsult.format('YYYY-MM-DD')}`)
        this.holidays = resp.data
      }
    },

    async getCalendar() {
      this.dentistCalendarWork = []
      const payload = {
        dates: this.days.map(day => day.date), // [2023-06-02, 2023-06-03, 2023-06-07]
        dentist: this.dentist, // objeto completo do dentista
      }
      const resp = await this.$store.dispatch('store-schedule/fetchCalendars', payload)
      this.dentistCalendarWork = resp.data
    },

    async setSchedules() {
      await this.getCalendar()
      this.$emit('getSchedules')
    },

    isHoliday(date) {
      return this.holidays.find(e => e.date.slice(0, 10) === date) || false
    },

    isScheduleDate(date) {
      const dentistDayTimesWork = this.getDentistDayTimesWork(date)
      const ocupedSchedules = this.schedules.filter(e => e.date.slice(0, 10) === date.format('YYYY-MM-DD')).map(e => e.date.slice(11, 16))
      const freeSchedules = dentistDayTimesWork.filter(el => !ocupedSchedules.includes(el))

      const calendarSchedule = this.dentistCalendarWork.find(e => e.date === date.format('YYYY-MM-DD'))
      if (calendarSchedule) {
        dentistDayTimesWork.push(calendarSchedule.times.map(e => e.start))
        freeSchedules.push(...dentistDayTimesWork.filter(el => !ocupedSchedules.includes(el)).flat())
      }

      const payload = {
        ocupedSchedules,
        dentistDayTimesWork,
        freeSchedules,
      }

      return payload
    },

    getDentistDayTimesWork(date) {
      const dentistDayTimesWork = []
      if (this.dentist && this.dentist.office_hour) {
        const dentistDaysWork = this.dentist.office_hour.days.filter(oh => oh.activated)
        const { start, end } = dentistDaysWork.find(dentistDayWork => dentistDayWork.value === this.getWeekday(date) + 1) || false

        let startDateTime = Date.parse(`2022-01-01 ${start}`)
        const endDateTime = Date.parse(`2022-01-01 ${end}`)

        while (startDateTime < endDateTime) {
          const time = new Date(startDateTime)
          dentistDayTimesWork.push(time.toLocaleTimeString('pt-BR', { hour: 'numeric', minute: 'numeric', hour12: false }))
          startDateTime += (this.dentist.office_hour.appointmentDuration * 60000)
        }
      }
      return dentistDayTimesWork
    },
  },
}
</script>

<style scoped>
.calendar-month {
  position: relative;
  background-color: #cfd7e3;
  border: solid 1px #b5c0cd;
}

.day-of-week {
  color: #3e4e63;
  font-size: 18px;
  background-color: #fff;
  padding-bottom: 5px;
  padding-top: 10px;
}

.day-of-week,
.days-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.day-of-week > * {
  text-align: right;
  padding-right: 5px;
}

.days-grid {
  height: 100%;
  position: relative;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  border-top: solid 1px #cfd7e3;
}
</style>
