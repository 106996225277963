import axios from '@axios'

export default {
  namespaced: true,
  state: {
    periods: [0, 1, 2], // 1 MATUTINO 2 VESPERTINO 3 NOTURNO
    schedule: {},
    selectedDays: [],
    patientSchedules: [],
    employeeSchedules: [],
    chairSchedules: [],
    holidaySchedules: [],
  },

  getters: {},

  mutations: {
    SET_SCHEDULE(state, payload) {
      state.schedule = { ...state.schedule, ...payload }
      // state.schedule = Object.assign({}, state.schedule, payload) //precisa ser assim para manter a reatividade
    },

    SET_SELECTED_DAYS(state, payload) {
      // ORDENA AS DATAS
      state.selectedDays = payload.sort((a, b) => new Date(a.date) - new Date(b.date))
    },

    SET_PATIENT_SCHEDULES(state, payload) {
      state.patientSchedules = payload // precisa ser assim para manter a reatividade
    },

    SET_EMPLOYEE_SCHEDULES(state, payload) {
      state.employeeSchedules = payload // precisa ser assim para manter a reatividade
    },

    SET_CHAIR_SCHEDULES(state, payload) {
      state.chairSchedules = payload // precisa ser assim para manter a reatividade
    },

    SET_HOLIDAY_SCHEDULES(state, payload) {
      state.holidaySchedules = payload // precisa ser assim para manter a reatividade
    },

    // COLOCA OS DADOS DOS AGENDAMENTOS EM SELECEDDAYS PARA PREENCHER A TABELA DE AGENDAS
    FILL_SELECTED_DAYS(state) {
      // console.time('FILL_SELECTED_DAYS')

      const selectedDays = JSON.parse(JSON.stringify(state.selectedDays))

      selectedDays.map(selectedDay => {
        // ESVAZIO AS AGENDAS EM TODOS OS DIAS PREENCHIDOS
        selectedDay.patientSchedule.splice(0)
        selectedDay.employeeSchedule.splice(0)
        selectedDay.chairSchedule.splice(0)
        selectedDay.holidaySchedule.splice(0)

        // PREENCHO OS DIAS COM AS AGENDAS RECEBIDAS NOS DIAS CORRESPONDENTES
        if (state.patientSchedules.length) {
          state.patientSchedules.map(schedule => {
            if (selectedDay.id === schedule.start.slice(0, 10)) {
              selectedDay.patientSchedule.push(schedule)
            }
            return true
          })
        }

        // PREENCHO OS DIAS COM AS AGENDAS RECEBIDAS NOS DIAS CORRESPONDENTES
        if (state.employeeSchedules.length) {
          state.employeeSchedules.map(schedule => {
            if (selectedDay.id === schedule.start.slice(0, 10)) {
              selectedDay.employeeSchedule.push(schedule)
            }
            return true
          })
        }

        // PREENCHO OS DIAS COM AS AGENDAS RECEBIDAS NOS DIAS CORRESPONDENTES
        if (state.chairSchedules.length) {
          state.chairSchedules.map(schedule => {
            if (selectedDay.id === schedule.start.slice(0, 10)) {
              selectedDay.chairSchedule.push(schedule)
            }
            return true
          })
        }

        // PREENCHO OS DIAS COM AS AGENDAS RECEBIDAS NOS DIAS CORRESPONDENTES
        if (state.holidaySchedules.length) {
          state.holidaySchedules.map(schedule => {
            if (selectedDay.id === schedule.date.slice(0, 10)) {
              selectedDay.holidaySchedule.push(schedule)
            }
            return true
          })
        }
        return true
      })

      state.selectedDays = selectedDays

      // console.timeEnd('FILL_SELECTED_DAYS')
    },
  },

  actions: {
    fetchFilter({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/schedules/fetch`, payload)
          .then(resp => {
            commit('SET_PATIENT_SCHEDULES', resp.data.patientSchedule)
            commit('SET_EMPLOYEE_SCHEDULES', resp.data.employeeSchedule)
            commit('SET_CHAIR_SCHEDULES', resp.data.chairSchedule)
            commit('SET_HOLIDAY_SCHEDULES', resp.data.holidaySchedule)
            return resolve(resp)
          })
          .catch(e => reject(e))
      })
    },

    // /////////////// AGENDA 1 HORÁCIO ////////////////////////////
    async fetchScheduleList(ctx, payload) {
      try {
        const resp = await axios
          .post(`${process.env.VUE_APP_API_URL}/schedules/schedule-list`, payload)
        return resp
      } catch (e) {
        return e
      }
    },

    async add(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/schedule`, payload)
          .then(resp => resolve(resp))
          .catch(e => reject(e))
      })
    },

    async update(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/schedule/${payload.id}`, payload)
          .then(resp => resolve(resp))
          .catch(e => reject(e))
      })
    },

    async delete(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/schedules/delete`, payload)
          .then(resp => resolve(resp))
          .catch(e => reject(e))
      })
    },

    async addCalendar(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/calendar`, payload)
          .then(resp => resolve(resp))
          .catch(e => reject(e))
      })
    },

    async deleteCalendar(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/calendar/delete`, payload)
          .then(resp => resolve(resp))
          .catch(e => reject(e))
      })
    },

    async unblockCalendar(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/calendar/unblock`, payload)
          .then(resp => resolve(resp))
          .catch(e => reject(e))
      })
    },

    async fetchCalendars(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/calendars/fetch`, payload)
          .then(resp => resolve(resp))
          .catch(e => reject(e))
      })
    },

    async fetchCalendarEmployees(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/calendars/employees/fetch`, payload)
          .then(resp => resolve(resp))
          .catch(e => reject(e))
      })
    },
  },
}
