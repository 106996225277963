<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
            style="height:100%"
          >
            <!-- Calendar -->
            <v-select
              v-model="calendarType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="[
                {label: 'Ver por Período', value: 'range'},
                {label: 'Ver por Semana', value: 'week'},
                {label: 'Ver por Dia', value: 'calendar'}
              ]"
              style="padding: 4px 4px 4px;"
              :reduce="val => val.value"
              @input="clearCalendar"
            />

            <div class="d-flex justify-content-center">
              <vc-calendar
                v-if="calendarType === 'calendar'"
                ref="calendar"
                trim-weeks
                :disabled-dates="{ weekdays: [1] }"
                :attributes="attributes"
                :is-dark="skin === 'dark'"
                @dayclick="onDayClick"
              />

              <vc-date-picker
                v-else-if="calendarType === 'range'"
                ref="calendar"
                v-model="dateRange"
                trim-weeks
                is-range
                :is-dark="skin === 'dark'"
              />

              <vc-calendar
                v-else-if="calendarType === 'week'"
                ref="calendar"
                trim-weeks
                :disabled-dates="{ weekdays: [1] }"
                :attributes="attributes"
                :is-dark="skin === 'dark'"
                @dayclick="onDayClickWeek"
              />
            </div>

            <!-- Filters -->
            <b-list-group class="list-group-messages cursor-pointer">
              <b-list-group-item
                v-for="filter in menuLeftSidebar"
                :key="filter.title + $route.path"
                :active="false"
                @click="triggerFunction(filter.function.name)"
              >
                <feather-icon
                  :icon="filter.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
                <!-- <b-badge
                  v-if="true"
                  pill
                  :variant="resolveFilterBadgeColor(filter.title)"
                  class="float-right"
                >
                  'oioioio'
                </b-badge> -->
              </b-list-group-item>
            </b-list-group>

            <!-- Labels -->
            <!-- <h6 class="section-label mt-3 mb-1 px-2">
              Labels
            </h6>

            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="label in emailLabel"
                :key="label.title + $route.path"
                :to="label.route"
                :active="true"
                @click="$emit('close-ltrim-weeks
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${label.color}`"
                />
                <span>{{ label.title }}</span>
              </b-list-group-item>
            </b-list-group> -->
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import useAppConfig from '@core/app-config/useAppConfig'

import {
  ref, onMounted, nextTick, computed, watch,
} from '@vue/composition-api'

import store from '@/store'
import vSelect from 'vue-select'

export default {
  directives: {
    Ripple,
  },
  components: {

    // BSV
    BListGroup,
    BListGroupItem,

    // 3rd Party
    VuePerfectScrollbar,
    vSelect,

  },
  setup(props, { emit }) {
    const { skin } = useAppConfig()
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const calendar = ref(null)

    const triggerFunction = (functionName, params) => {
      emit('handle')
      emit(functionName, params || null)
    }

    const menuLeftSidebar = [
      { title: 'Criar agendamento', icon: 'PlusCircleIcon', function: { name: 'handleSearchPatient', params: { foo: '' } } },
      { title: 'Excluir agendamento', icon: 'StarIcon', function: { name: 'handleDelete', params: { foo: '' } } },
      { title: 'Incluir horário', icon: 'InfoIcon', function: { name: 'handleAddCalendar', params: { foo: '' } } },
      { title: 'Excluir horário', icon: 'TrashIcon', function: { name: 'handleDeleteCalendar', params: { foo: '' } } },
      { title: 'Próxima vaga', icon: 'CalendarIcon', function: { name: 'handleDentistScheduleInfo', params: { foo: '' } } },
    ]

    // const emailLabel = [
    //   { title: 'Personal', color: 'success', route: { name: 'apps-email-label', params: { label: 'personal' } } },
    //   { title: 'Company', color: 'primary', route: { name: 'apps-email-label', params: { label: 'company' } } },
    //   { title: 'Important', color: 'warning', route: { name: 'apps-email-label', params: { label: 'important' } } },
    //   { title: 'Private', color: 'danger', route: { name: 'apps-email-label', params: { label: 'private' } } },
    // ]

    // const resolveFilterBadgeColor = filter => {
    //   if (filter === 'Draft') return 'light-warning'
    //   if (filter === 'Spam') return 'light-danger'
    //   return 'light-primary'
    // }

    const dateObj = new Date()
    const [DD, MM, YYYY] = dateObj.toLocaleDateString('pt-BR').split('/')
    const today = ref({
      id: `${YYYY}-${MM}-${DD}`,
      ariaLabel: null,
      date: dateObj,
      isToday: true,
      weekdayPosition: parseInt(dateObj.getDay() + 1, 10),
    })
    const calendarType = ref('week')
    const dateRange = ref({})

    const selectedDays = computed({
      get: () => store.state['store-schedule'].selectedDays,
      set: val => {
        store.commit('store-schedule/SET_SELECTED_DAYS', val)
      },
    })

    // used to highlight days of week on calendar
    const attributes = computed(() => selectedDays.value.map(date => ({
      highlight: true,
      dates: date.date,
    })))

    const onDayClick = day => {
      const selectedDaysLocal = JSON.parse(JSON.stringify(selectedDays.value))
      const idx = selectedDaysLocal.findIndex(d => d.id === day.id)
      if (idx >= 0) {
        selectedDaysLocal.splice(idx, 1)
      } else if (day.date) {
        // Criar um novo objeto Date em UTC-0 diretamente
        const utcDate = new Date(Date.UTC(
          day.date.getFullYear(), // Ano em UTC
          day.date.getMonth(), // Mês em UTC
          day.date.getDate(), // Dia do mês em UTC
          0, 0, 0,
        ))

        selectedDaysLocal.push({
          patientSchedule: [],
          employeeSchedule: [],
          chairSchedule: [],
          holidaySchedule: [],
          id: day.id,
          ariaLabel: day.ariaLabel,
          date: utcDate,
          isToday: day.isToday,
          weekdayPosition: day.weekdayPosition + 1,
        })
      }

      emit('change-date', selectedDaysLocal)
    }

    const weekDays = date => {
      const dt = new Date(date)
      const wDays = []
      // Starting Monday not Sunday
      dt.setDate((dt.getDate() - dt.getDay() + 1))
      for (let i = 0; i < 6; i += 1) {
        wDays.push(new Date(dt))
        dt.setDate(dt.getDate() + 1)
      }
      return wDays
    }

    const onDayClickWeek = date => {
      const days = weekDays(date.id)
      const selectedDaysLocal = []
      days.forEach(day => {
        const [DDl, MMl, YYYYl] = day.toLocaleDateString('pt-BR').split('/')
        selectedDaysLocal.push({
          patientSchedule: [],
          employeeSchedule: [],
          chairSchedule: [],
          holidaySchedule: [],
          id: `${YYYYl}-${MMl}-${DDl}`,
          ariaLabel: null,
          date: day,
          isToday: day.toLocaleDateString('pt-BR') === today.value.date.toLocaleDateString('pt-BR'),
          weekdayPosition: parseInt(day.getDay() + 1, 10),
        })
      })

      // Mon Jun 24 2024 01:00:00 GMT+0100 (Western European Summer Time)
      // "2024-06-24T00:00:00.000Z"

      // SÓ ALTERA O STATE CASO A SEMANA MUDE
      if (JSON.stringify(selectedDaysLocal.map(e => e.id)) !== JSON.stringify(selectedDays.value.map(e => e.id))) {
        emit('change-date', selectedDaysLocal)
      }
    }

    watch(dateRange, () => {
      if (calendarType.value === 'range') {
        const selectedDaysLocal = []
        // to avoid modifying the original date

        const startDate = dateRange.value.start
        const endDate = dateRange.value.end

        while (startDate <= endDate) {
          const [DDl, MMl, YYYYl] = startDate.toLocaleDateString('pt-BR').split('/')
          selectedDaysLocal.push({
            patientSchedule: [],
            employeeSchedule: [],
            chairSchedule: [],
            holidaySchedule: [],
            id: `${YYYYl}-${MMl}-${DDl}`,
            ariaLabel: null,
            date: new Date(startDate),
            isToday: startDate.toLocaleDateString('pt-BR') === today.value.date.toLocaleDateString('pt-BR'),
            weekdayPosition: parseInt(startDate.getDay() + 1, 10),
          })
          startDate.setDate(startDate.getDate() + 1)
        }
        emit('change-date', selectedDaysLocal)
      }
    })

    const clearCalendar = (date = null) => {
      selectedDays.value = []
      dateRange.value = {}
      if (calendarType.value === 'calendar') onDayClick(date || today.value)
      else if (calendarType.value === 'week') onDayClickWeek({ id: date || today.value.id })
    }

    onMounted(() => {
      nextTick(() => {
        if (calendarType.value === 'calendar') onDayClick(today.value)
        else if (calendarType.value === 'week') onDayClickWeek({ id: today.value.id })
      })
    })

    return {
      // UI
      perfectScrollbarSettings,
      isDynamicRouteActive,
      // resolveFilterBadgeColor,

      // Filter & Labels
      menuLeftSidebar,
      // emailLabel,

      calendarType,
      attributes,
      dateRange,
      skin,
      calendar,

      triggerFunction,
      onDayClick,
      onDayClickWeek,
      clearCalendar,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
