<template>
  <div class="calendar-date-indicator">
    {{ selectedMonth }}
  </div>
</template>

<script>
export default {
  props: {
    selectedDate: {
      type: Object,
      required: true,
    },
  },

  computed: {
    selectedMonth() {
      const date = this.selectedDate.format('MMMM YYYY')
      return date[0].toUpperCase() + date.substring(1)
    },
  },
}
</script>

<style scoped>
.calendar-date-indicator {
  font-size: 24px;
  font-weight: 600;
  color: #2c3e50;
}
</style>
