<template>
  <b-modal
    id="modal-handle-arrival"
    v-model="showLocal"
    centered
    size="md"
    title="Por Favor Confirme esta Ação"
    @hidden="hidden"
  >

    <div>
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          Atualizar a hora de chegada do paciente?
        </b-col>
      </b-row>
    </div>

    <template #modal-footer="{ cancel }">

      <b-button
        variant="outline-secondary"
        @click="cancel()"
      >
        Cancelar
      </b-button>
      <b-button
        variant="primary"
        @click="reset"
      >
        Resetar
      </b-button>
      <b-button
        variant="primary"
        @click="mark"
      >
        Marcar
      </b-button>

    </template>

  </b-modal>
</template>

<script>
import { toRefs, ref, watch } from '@vue/composition-api'
import {
  BModal, BButton, BCol, BRow,
} from 'bootstrap-vue'
import { toISOStringWithTimezone } from '@core/utils/filter'

export default {
  components: {
    BModal,
    BButton,
    BCol,
    BRow,

  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const showLocal = ref(false)
    const dataLocal = ref({})
    const user = ref(JSON.parse(localStorage.getItem('userData')))

    // necessário para observar props
    const { show, data } = toRefs(props)
    watch(show, newValue => {
      showLocal.value = newValue
    })

    watch(data, newValue => {
      dataLocal.value = JSON.parse(JSON.stringify(newValue))
    })

    const mark = () => {
      dataLocal.value.arrival_time = toISOStringWithTimezone(new Date())
      dataLocal.value.auxiliar = user

      emit('save', null, null, dataLocal.value)
      emit('hidden')
    }

    const reset = () => {
      dataLocal.value.arrival_time = null
      dataLocal.value.auxiliar = null
      emit('save', null, null, dataLocal.value)
      emit('hidden')
    }

    const hidden = () => {
      emit('hidden')
      dataLocal.value = JSON.parse(JSON.stringify(data.value))
    }

    return {
      showLocal,
      dataLocal,
      hidden,
      mark,
      reset,
    }
  },
}
</script>

<style>

</style>
