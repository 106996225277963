import {
  ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import {
  toISOStringWithTimezone,
} from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userStoreModule from '@/views/apps/user/userStoreModule'
import store from '@/store'
import scheduleStoreModule from './scheduleStoreModule'

export default function useSchedule(root) {
  const SCHEDULE_STORE_MODULE_NAME = 'store-schedule'
  const USER_APP_STORE_MODULE_NAME = 'app-user'

  // Register module
  if (!store.hasModule(SCHEDULE_STORE_MODULE_NAME)) store.registerModule(SCHEDULE_STORE_MODULE_NAME, scheduleStoreModule)
  if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(SCHEDULE_STORE_MODULE_NAME)) store.unregisterModule(SCHEDULE_STORE_MODULE_NAME)
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  })

  // Use toast
  const toast = useToast()

  const refScheduleListTable = ref(null)

  const totalData = ref(0)
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)

  const types = ref(['CLINICAL']) // CLINICAL, ODONTOLOGICAL, COMMITMENT - For search (array)
  const employeeOptions = ref([])
  const employee = ref(null)
  const refScheduleFilter = ref(null)
  const leftSidebar = ref(null)
  const items = ref([])
  const selected = ref([])
  const allCheckBoxSelected = ref(false)
  const indeterminateCheckBox = ref(false)
  const showOverlay = ref(false)
  const dentalPlanOptions = ref([])
  const calendars = ref([])
  const dentistsOfDays = ref([])

  // Table Columns
  const tableColumns = ref([
    {
      key: 'select',
      label: '#',
      sortable: false,
      edit: false,
      tdClass: 'selectTdClass',
    },
    {
      key: 'status',
      label: 'Status',
      sortable: true,
      edit: false,
      tdClass: 'confirmTdClass',
    },
    {
      key: 'time',
      label: 'Horário',
      sortable: true,
      edit: false,
      tdClass: 'timeTdClass',
    },
    {
      key: 'code',
      label: 'Ficha',
      sortable: true,
      edit: false,
      tdClass: 'codeTdClass',
    },
    {
      key: 'name',
      label: 'Nome',
      sortable: true,
      edit: false,
      tdClass: 'patientNameTdClass',
    },
    {
      key: 'dental_plan',
      label: 'Plano',
      sortable: true,
      edit: false,
      tdClass: 'dentalPlanTdClass',
    },
    {
      key: 'observation',
      label: 'Obs.',
      sortable: true,
      edit: false,
      tdClass: 'obsTdClass',
    },
    {
      key: 'arrival_time',
      label: 'Chegada',
      sortable: true,
      edit: false,
      tdClass: 'arrivalTimeTdClass',
    },
    {
      key: 'auxiliar',
      label: 'Auxiliar',
      sortable: true,
      edit: false,
      tdClass: 'auxTdClass',
    },
  ])

  const selectedDays = computed(() => (store.state['store-schedule'] ? store.state['store-schedule'].selectedDays : []))

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        title: titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const itemsData = computed(() => items.value.map(item => item.data).flat())

  const catchErr = e => {
    showOverlay.value = false
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response && e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  const statusOptions = {
    NOT_CONFIRMED: 'Não Confirmado',
    CONFIRMED: 'Confirmado',
    CANCELED: 'Cancelado',
    ABSENSE: 'Faltou',
    ATTENDED: 'Atendido',
    INPROGRESS: 'Em Atendimento',
    BLOCKED: 'Bloqueado',
    EMERGENCY: 'Emergência',
    EMPTY: 'Livre',
  }
  const handleStatus = status => statusOptions[status] || status

  const handleObservationShowModal = ref(false)
  const observationData = ref({})

  const handleArrivalShowModal = ref(false)
  const arrivalData = ref({})

  const handleDentistScheduleInfoShowModal = ref(false)
  const dentistScheduleInfoData = ref({})

  const handleAddCalendarShowModal = ref(false)

  const handleObservation = data => {
    observationData.value = data
    handleObservationShowModal.value = true
  }

  const handleArrival = rowData => {
    arrivalData.value = rowData
    handleArrivalShowModal.value = true
  }

  /**
   * Altera o estado do checkbox da linha ao clicar na mesma
   * Não está sendo utilizado no momento
   * @param {*} row
   */
  const toggleCheckbox = row => {
    const idx = selected.value.findIndex(el => el.uuid === row.uuid)
    if (idx >= 0) selected.value.splice(idx, 1)
    else selected.value.push(row)
  }

  const toggleAllCheckBox = checked => {
    selected.value = checked ? itemsData.value : []
  }

  /**
   * Só permite edição do campo code para novos registros
   * Outros campos serão editáveis somente em updates
   * @param {} rowData
   * @param {*} col
   */
  const editCol = (rowData, col) => {
    if (!rowData.avaiable || rowData.status === 'CANCELED') return

    if (col.key === 'code' || rowData.id) {
      // eslint-disable-next-line no-param-reassign
      rowData.edit = col.key
    }

    if (col.key === 'arrival_time' && rowData.id) {
      // eslint-disable-next-line no-param-reassign
      rowData.edit = null
      handleArrival(rowData)
    }
  }

  const mountScheduleTimesArray = date => {
    const times = ref([])
    date.times.forEach(time => {
      times.value.push({
        id: null,
        calendar_id: date.calendar_id,
        avaiable: time.avaiable,
        uuid: crypto.randomUUID(),
        edit: false,
        status: time.avaiable ? 'EMPTY' : 'BLOCKED',
        type: 'CLINICAL',
        duration: employee.value.data.office_hour ? employee.value.data.office_hour.appointmentDuration : 30,
        service_time: null,
        date: `${date.date.slice(0, 10)} ${time.start}:00`,
        time: time.start,
        code: null,
        name: '',
        patientId: null,
        dental_plan: null,
        observation: '',
        arrival_time: null,
        employee_id: employee.value.data.id,
        chair_id: null,
        auxiliar: null,
        dental_plan_id: null,
      })
    })
    return times.value
  }

  const mountScheduleTimes = (date, employeeDaysWork) => {
    const times = ref([])
    if (date.times) {
      times.value = mountScheduleTimesArray(date)
      return times
    }

    if (employeeDaysWork.length) {
      const employeeDaysWorkFiltered = employeeDaysWork.find(edw => edw.value === date.weekdayPosition)
      const { start, end } = employeeDaysWorkFiltered

      let startDateTime = Date.parse(`2022-01-01 ${start}`)
      const endDateTime = Date.parse(`2022-01-01 ${end}`)

      while (startDateTime < endDateTime) {
        const time = new Date(startDateTime)
        const timestamp = new Date(date.date)
        timestamp.setHours(time.getHours(), time.getMinutes(), 0, 0)

        times.value.push({
          id: null,
          calendar_id: null,
          avaiable: true,
          uuid: crypto.randomUUID(),
          edit: false,
          status: 'EMPTY',
          type: 'CLINICAL',
          duration: employee.value.data.office_hour ? employee.value.data.office_hour.appointmentDuration : 30,
          service_time: null,
          date: toISOStringWithTimezone(timestamp, 'dateTime'),
          time: time.toLocaleTimeString('pt-BR', { hour: 'numeric', minute: 'numeric', hour12: false }),
          code: null,
          name: '',
          patientId: null,
          dental_plan: null,
          observation: '',
          arrival_time: null,
          employee_id: employee.value.data.id,
          chair_id: null,
          auxiliar: null,
          dental_plan_id: null,
        })
        startDateTime += (employee.value.data.office_hour.appointmentDuration * 60000)
      }
    }
    return times
  }

  const handleRowTime = (date, employeeDaysWork) => {
    let times = ref([])
    times = mountScheduleTimes(date, employeeDaysWork)

    times.value.sort((a, b) => Date.parse(`1970/01/01 ${a.time}`) - Date.parse(`1970/01/01 ${b.time}`))

    return times.value
  }

  const fillSchedule = (filteredDates, employeeDaysWork) => {
    // eslint-disable-next-line no-param-reassign
    filteredDates = filteredDates.sort((a, b) => new Date(a.id) - new Date(b.id))
    return filteredDates.map(date => {
      const result = date
      result.data = handleRowTime(date, employeeDaysWork)
      return result
    })
  }

  const fetchScheduleList = async () => {
    const employeeDaysWork = []
    const filteredDates = []

    if (employee.value && employee.value.data.office_hour) {
      employeeDaysWork.push(...employee.value.data.office_hour.days.filter(oh => oh.activated))
      filteredDates.push(...selectedDays.value.filter(sd => employeeDaysWork.some(
        some => (sd ? sd.weekdayPosition === some.value : false),
      )))
    }

    try {
      const payload = {
        dates: selectedDays.value.map(e => e.id),
        dentist: employee.value.data,
      }
      calendars.value = await store.dispatch('store-schedule/fetchCalendars', payload)

      calendars.value.data.forEach(el => {
        filteredDates.push({
          calendar_id: el.id,
          patientSchedule: [],
          employeeSchedule: [],
          chairSchedule: [],
          holidaySchedule: [],
          id: el.date,
          ariaLabel: null,
          times: el.times,
          date: new Date(el.date).toISOString(),
          isToday: new Date(el.date).toLocaleDateString('pt-BR') === new Date().toLocaleDateString('pt-BR'),
          weekdayPosition: parseInt(new Date(el.date).getDay() + 1, 10),
        })
      })

      return fillSchedule(filteredDates, employeeDaysWork)
    } catch (e) {
      catchErr(e)
    }
    return []
  }

  const groupDates = scheduleDates => scheduleDates.reduce((accumulator, current) => {
    const idx = accumulator.findIndex(item => item.date.slice(0, 10) === current.date.slice(0, 10))
    if (idx >= 0) {
      accumulator[idx].data.push(...current.data)
      // eslint-disable-next-line no-param-reassign
      accumulator[idx].data = accumulator[idx].data.sort((a, b) => Date.parse(`1970/01/01 ${a.time}`) - Date.parse(`1970/01/01 ${b.time}`))
    } else {
      accumulator.push(current)
    }
    return accumulator
  }, [])

  const refetchData = async () => {
    if (!employee.value) {
      items.value = []
      return
    }

    const fetchSchedules = await fetchScheduleList()
    const fetchSchedulesGrouped = groupDates(fetchSchedules)
    items.value = fetchSchedulesGrouped

    const response = await store
      .dispatch('store-schedule/fetchScheduleList', {
        sortBy: sortBy.value,
        sort: isSortDirDesc.value ? 'desc' : 'asc',
        types: types.value,
        dates: items.value.map(el => el.date),
        getByDate: true,
        // patient_id: patientId.value,
        employee_id: employee.value.data.id,
        // chair_id: chair.value ? chair.value.id : null,
      })

    const { data, total } = response.data
    totalData.value = total

    data.forEach(dt => {
      const idx = items.value.findIndex(item => item.date.slice(0, 10) === dt.date.slice(0, 10))
      if (idx >= 0) {
        dentalPlanOptions.value = dt.patient?.dental_plans || []
        const result = []
        dentalPlanOptions.value.map(e => result.push({
          value: e.id,
          label: e.name,
          data: e,
        }))
        dentalPlanOptions.value = result.sort((a, b) => a.label.localeCompare(b.label))

        const tmp = {
          id: dt.id,
          edit: false,
          avaiable: true, // aqui só não é avaiable qdo estiver bloqueada
          status: dt.status,
          type: dt.type,
          duration: dt.duration,
          service_time: dt.service_time,
          date: dt.date,
          time: dt.date.substr(11, 5),
          code: dt.patient.code,
          patientId: dt.patient.id,
          name: dt.patient.name,
          dental_plan: dt.dental_plan?.name,
          observation: dt.observation,
          arrival_time: dt.arrival_time,
          employee_id: dt.employee_id,
          chair_id: dt.chair_id,
          auxiliar: dt.auxiliar,
          dental_plan_id: dt.dental_plan_id,
        }

        const idx2 = items.value[idx].data.findIndex(item => item.date.slice(11, 19) === dt.date.slice(11, 19))
        if (idx2 >= 0) {
          const tForReactivity = items.value[idx]
          tForReactivity.data.splice(idx2, 1, tmp)
          items.value.splice(idx, 1, tForReactivity)
        }
      }
    })

    items.value = items.value.map(el => {
      // eslint-disable-next-line no-param-reassign
      el.data = el.data.reduce((acc, curr) => {
        const idx2 = acc.findIndex(acc_ => acc_.time === curr.time && acc_.status !== 'CANCELED' && acc_.status === 'EMPTY')
        if (idx2 >= 0) {
          acc[idx2] = curr
        } else {
          acc.push(curr)
        }
        return acc
      }, [])
      return el
    }).flat()
  }

  watch([searchQuery, types], () => {
    refetchData()
  })

  watch(selected, val => {
    if (val.length === 0) {
      indeterminateCheckBox.value = false
      allCheckBoxSelected.value = false
    } else if (val.length === itemsData.value.length) {
      indeterminateCheckBox.value = false
      allCheckBoxSelected.value = true
    } else {
      indeterminateCheckBox.value = true
      allCheckBoxSelected.value = false
    }
  })

  const saveSchedules = async patient => {
    try {
      showOverlay.value = true
      // se seleciono um horário já preenchido nada é feito neste horário
      selected.value = selected.value.filter(e => e.id === null)
      selected.value.map(e => {
        e.status = 'NOT_CONFIRMED'
        return e
      })
      await store.dispatch('store-schedule/add', { patient, times: selected.value })
      selected.value = []
      refetchData()
      showOverlay.value = false
    } catch (e) {
      catchErr(e)
    }
  }

  const saveSchedule = async (colKey, value, rowData) => {
    try {
      const tmpRowData = JSON.parse(JSON.stringify(rowData))
      if (colKey === 'dental_plan') tmpRowData.dental_plan_id = value.value
      else if (colKey) tmpRowData[colKey] = value
      showOverlay.value = true
      if (!rowData.id && tmpRowData.code) {
        tmpRowData.status = 'NOT_CONFIRMED'
        const payload = {
          times: [tmpRowData],
        }
        await store.dispatch('store-schedule/add', payload)
      } else if (rowData.id) {
        await store.dispatch('store-schedule/update', tmpRowData)
      }
      refetchData()
      showOverlay.value = false
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  const cancelSchedules = async schedules => {
    try {
      showOverlay.value = true
      const ids = schedules.map(e => e.id)
      if (ids.length) await store.dispatch('store-schedule/delete', ids)
      refetchData()
      showOverlay.value = false
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  const fetchEmployeeOptions = async () => {
    const resp = await store.dispatch('app-user/fetch', {
      roles: ['ADMIN', 'DENTIST'],
    })

    if (resp.data) {
      employeeOptions.value = resp.data.map(o => ({
        value: o.id,
        label: `${o.name} ${o.cpf || ''}`,
        data: o,
      }))
    }
  }

  const employeeFilter = (val = null) => {
    employee.value = val
    refetchData()
  }

  const handleDentistScheduleInfo = () => {
    dentistScheduleInfoData.value = employee.value ? employee.value.data : {}
    handleDentistScheduleInfoShowModal.value = true
  }

  const handleAddCalendar = () => {
    dentistScheduleInfoData.value = employee.value ? employee.value.data : {}
    handleAddCalendarShowModal.value = true
  }

  const changeDate = async days => {
    store.commit('store-schedule/SET_SELECTED_DAYS', days)
    store.commit('store-schedule/FILL_SELECTED_DAYS')
    const resp = await store.dispatch('store-schedule/fetchCalendarEmployees', selectedDays.value.map(e => e.id))
    dentistsOfDays.value = resp.data

    // eslint-disable-next-line no-unused-expressions
    refScheduleFilter.value && refScheduleFilter.value.filterEmployeeOptions(null, dentistsOfDays.value)
    selected.value = []
  }

  const getPayloadForDateChange = date => ({
    patientSchedule: [],
    employeeSchedule: [],
    chairSchedule: [],
    holidaySchedule: [],
    id: date,
    ariaLabel: null,
    date: new Date(date),
    isToday: new Date(date).toLocaleDateString('pt-BR') === new Date().toLocaleDateString('pt-BR'),
    weekdayPosition: parseInt(new Date(date).getDay() + 1, 10),
  })

  const deleteCalendar = async (time, dentist) => {
    try {
      showOverlay.value = true
      const payload = {
        time,
        dentist,
      }
      await store.dispatch('store-schedule/deleteCalendar', payload)
      refetchData()
      showOverlay.value = false
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  const unblockCalendar = async () => {
    try {
      showOverlay.value = true
      const payload = {
        time: selected.value,
        dentist: employee.value.data,
      }
      await store.dispatch('store-schedule/unblockCalendar', payload)
      refetchData()
      showOverlay.value = false
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  const handleDeleteCalendar = () => {
    let selectedLocal = JSON.parse(JSON.stringify(selected.value))

    if (selected.value.length === 0) {
      root.$swal({
        title: 'Aviso!',
        text: 'Selecione ao menos um horário na agenda!',
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
      return
    }

    if (selected.value.some(e => e.patientId)) {
      root.$swal({
        title: 'Aviso!',
        text: 'Não é possível excluir horários agendados! Por favor exclua os agendamentos antes de excluir os horários.',
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
      return
    }

    root.$swal({
      title: 'Tem certeza?',
      text: 'Deseja excluir o registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-secondary ml-1',
      },
      buttonsStyling: false,
    }).then(async result => {
      if (result.value) {
        await deleteCalendar(selectedLocal, employee.value.data)
        selectedLocal = []
        root.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'O horário foi excluído.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }
    })
  }

  const addCalendar = async (time, dentist) => {
    try {
      showOverlay.value = true
      const payload = {
        time: time.value,
        dentist: dentist.value,
      }
      await store.dispatch('store-schedule/addCalendar', payload)
      if (leftSidebar.value) {
        leftSidebar.value.calendarType = 'calendar'
        await changeDate([getPayloadForDateChange(time.value.date)])
        leftSidebar.value.calendar.move(time.value.date)
        refScheduleFilter.value.filterEmployeeOptions(dentist.value.id, dentistsOfDays.value)
      }
      handleAddCalendarShowModal.value = false
      refetchData()
      showOverlay.value = false
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  const dupliqueCalendarIfCanceled = async (val, row) => {
    if (val !== 'CANCELED') return
    const d = new Date(row.date)
    d.setMinutes(d.getMinutes() + 1)
    const time = {
      value: {
        date: row.date.slice(0, 10),
        times: [{
          avaiable: true,
          start: `${(d.getHours() < 10 ? '0' : '') + d.getHours()}:${(d.getMinutes() < 10 ? '0' : '') + d.getMinutes()}`,
        }],
      },
    }
    await addCalendar(time, { value: employee.value.data })
  }

  const selectTimeSchedule = async (day, time, dentist) => {
    if (leftSidebar.value) {
      leftSidebar.value.calendarType = 'calendar'
      await changeDate([getPayloadForDateChange(day.value)])
      leftSidebar.value.calendar.move(day.value)
      refScheduleFilter.value.filterEmployeeOptions(dentist.value.id, dentistsOfDays.value)

      // TODO ISTO ESTA AQUI POR QUE A LINHA ABAIXO EXECUTA ANTES DA NOVA LISTA DE AGENDAS ESTAR FINALIZADA PEGANDO ASSIM A UUID ANTERIOR
      // O CORRETO AQUI É ESPERAR QUE AS LINHA DA AGENDA SEGAM MONTADAS E SÓ DEPOIS CORRER A LINH ABAIXO
      setTimeout(() => {
        selected.value = itemsData.value.filter(e => e.date === `${day.value} ${time}:00`)
      }, 700)
    }

    handleDentistScheduleInfoShowModal.value = false
  }

  const dentalPlan = ref(null)

  const addProceduresShowModal = ref(false)

  const today = ref(new Date().toISOString().split('T')[0])

  return {
    searchQuery,
    sortBy,
    isSortDirDesc,
    refScheduleListTable,
    totalData,
    dentalPlan,
    addProceduresShowModal,
    today,
    refScheduleFilter,
    leftSidebar,
    items,
    itemsData,
    selected,
    allCheckBoxSelected,
    indeterminateCheckBox,
    tableColumns,
    employeeOptions,
    showOverlay,
    dentalPlanOptions,
    handleObservationShowModal,
    observationData,
    handleArrivalShowModal,
    arrivalData,
    handleDentistScheduleInfoShowModal,
    handleAddCalendarShowModal,
    dentistScheduleInfoData,

    fetchEmployeeOptions,
    employeeFilter,
    showToast,
    catchErr,
    handleStatus,
    handleObservation,
    handleDentistScheduleInfo,
    handleAddCalendar,
    handleDeleteCalendar,
    unblockCalendar,
    addCalendar,
    deleteCalendar,
    toggleCheckbox,
    toggleAllCheckBox,
    editCol,
    refetchData,
    fetchScheduleList,
    changeDate,
    saveSchedule,
    saveSchedules,
    dupliqueCalendarIfCanceled,
    cancelSchedules,
    selectTimeSchedule,
  }
}
